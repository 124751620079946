/**
 * Output component
 * 
 */

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';

export default class Output extends PureComponent {

	constructor(props) {

		super(props);

    	this.handleClose = this.handleClose.bind(this);
	}

	handleClose(event) {
		
		event.preventDefault();

		const target = event.currentTarget;
		const output = target.closest('.layout-output');

		output.classList.remove('show');
		ReactDOM.unmountComponentAtNode(output);
	}

	render() {

		const { children: output, alert } = this.props;
						
		return (

			<div className={'alert alert-' + alert + ' alert-dismissible'} role="alert">{output}<button type="button" className="close" onClick={this.handleClose} aria-label="Close"><span aria-hidden="true">&times;</span></button></div>
		)
	}
}
