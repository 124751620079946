/**
 * Layout theme component
 * 
 */

import { Component } from 'react';
import PropTypes from 'prop-types';
import withSideEffect from 'react-side-effect';

class Theme extends Component {

	render() {
		
		return this.props.children || null;
	}
}

Theme.propTypes = {

	props: PropTypes.string
}

const bodyState = (props) => {

	let theme = '';

	props.forEach(function (property) { theme = property.theme; });

	return theme;
}

const bodyChange = (theme) => {

	if (theme !== null) {

		document.body.className = theme;
	}
}

export default withSideEffect(bodyState, bodyChange)(Theme);