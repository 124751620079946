/**
 * Radio group component
 * 
 */

import React, { PureComponent } from 'react';

// Validation

import Helpers from '../validation/helpers';

export default class Radio extends PureComponent {

	static displayName = 'radio';

	constructor(props) {

		super(props);

    	this.state = {

    		value: props.checked,
    		data: { legend: props.legend, label: props.label, name: props.name, id: props.id + '-' + props.name, type: 'radio', required: props.required, value: props.checked }
    	};

    	this.components = {};
    	this.props.update(this.state.data);
    	this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {

		const target = event.target;
		const radios = target.closest('.radio');
		const checked = radios.querySelectorAll('input[type="radio"]:checked');
		const group = target.closest('.form-group');
		const value = target.value;

		let temp = this.state.data;

		temp.value = value;

		this.setState({ value: value, data: temp });
		this.props.update(this.state.data);
		
		Helpers.removeClass(radios.getElementsByTagName('label'), 'checked');
		Helpers.addClass(checked, 'checked', true);

		Helpers.removeClass(radios.getElementsByTagName('input'), 'is-invalid');
		group.classList.remove('invalid');
	}

	clear() {
		
		let temp = this.state.data;

		temp.value = '';
		
		this.setState({ value: '', data: temp });
	}

	render() {

		const { children } = this.props;

		const id = this.state.data.id;
		const name = this.state.data.name;
		const legend = this.state.data.legend;
		const required = this.state.data.required;
		const screenreader = this.state.data.label === 'true' ? {} : { 'className': 'sr-only' };

		const options = Helpers.recursiveCloneChildren(this, children, { type: this.state.data.type, name: name, id: id, checked: this.state.value, update: this.handleChange, }, ['option']);
			
		return (

			<fieldset className="form-group">
                <legend {...screenreader}>{legend}</legend>
                <div className="form-option" id={id} aria-required={required} aria-invalid="false" role="radiogroup">
                    {options}
                </div>
            </fieldset>
		)
	}
}
