/**
 * Nav component
 * 
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';

// Reducers

import { toggleDrawer } from '../../utils/reducers/drawer';

// Functions

import Helpers from '../../utils/functions/helpers';
import Tracking from '../../utils/functions/analytics';

class Nav extends Component {

	constructor(props) {

		super(props);
		
    	this.handleClick = this.handleClick.bind(this);
	}

	handleClick(event) {

    	if (this.props.state.drawer === 'open') {

    		this.props.toggleDrawer(this.props.state);
    	}

		Tracking.navigation({ action: 'link', label: event.target.text });
	}
	
	render() {
		
		const { nav, uid, className, layout } = this.props;

		const menu = nav && nav.find( menu => menu.uid === uid );
		const classes = 'menu ' + layout;
		const name = 'nav ' + className;

		if (menu) {

			const { menu_links: links, show_title: show, title } = menu.data;

			return (

				<nav className={classes}>

					{ show.toLowerCase() === 'yes' && <h5>{title}</h5> }

					<ul className={name}>
						{	

	                		links.map((item, i) => {
	                			
	                			const url = Helpers.linkResolver(item.link);
	                			const target = item.link.link_type === 'Document' ? {} : { target: '_blank' };
	                			
	                			if (url === undefined) {

	                				return null;
	                			}
	                			else {

	                				return <li key={i} className="nav-item"><NavLink exact to={url} {...target} className="nav-link" onClick={this.handleClick}>{item.label[0].text}</NavLink></li>;
	                			}
	                		})
	                	}
					</ul>
				</nav>
			)
		}
		else {

			return null;
		}
	}
}

// Get state from react redux

const mapStateToProps = (state) => ({ state: state.drawer });

// Update state in react redux

const mapDispatchToProps = (dispatch) => {

	return {

		dispatch, 

		toggleDrawer: (state) => {

			dispatch(toggleDrawer(state));
		}
	}
};

// Exporting our component via react redux

export default connect( mapStateToProps, mapDispatchToProps )(withRouter(Nav));
