/**
 * Form error statements
 *
 */

import React from 'react';

const Errors = {

	empty: field => {
		
		return <React.Fragment>The <strong>{field}</strong> field can not be empty.</React.Fragment>;
	},

	email: field => {
		
		return <React.Fragment>The submitted information is not a valid e-mail address, please check the <strong>{field}</strong> field.</React.Fragment>;
	},

	unicode: field => {
		
		return <React.Fragment>Your submitted information cannot include any non supported characters, please check the <strong>{field}</strong> field.</React.Fragment>;
	},

	url: field => {
		
		return <React.Fragment>The submitted information is not a valid URL, please check the <strong>{field}</strong> field.</React.Fragment>;
	},

	tel: field => {
		
		return <React.Fragment>The submitted information is not a valid phone number, please check the <strong>{field}</strong> field.</React.Fragment>;
	},

	international: field => {
		
		return <React.Fragment>Phone number is missing country code, please check the <strong>{field}</strong> field.</React.Fragment>;
	},

	number: field => {
		
		return <React.Fragment>The submitted information is not a valid number, please check the <strong>{field}</strong> field.</React.Fragment>;
	}
}

export default Errors

