/**
 * Header component
 * 
 */

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Reducers

import { toggleDrawer } from '../../../utils/reducers/drawer';

// Components

import Logo from '../../../components/logo/logo';

class Header extends React.Component {

	constructor(props) {
		
		super(props);

		this.handleClick = this.handleClick.bind(this);

		this.state = {

			sticky: false
		}
    }

    componentDidMount() {

    	var scrolling = this;

    	document.addEventListener('scroll', function(e) {

    		scrolling.handleScroll();
    	});
    }

    handleClick(event) {
    	
    	this.props.toggleDrawer(this.props.state);
    }

    handleScroll() {

    	if (window.pageYOffset > 160) {

    		this.setState({ sticky: true });
    	}
    	else {

    		this.setState({ sticky: false });
    	}
    }
	
	render() {
		
		const { sticky } = this.state;
		const { children, settings } = this.props;

		if (settings) {

			const { company_header_logo } = settings;

			return (

				<header className={sticky ? 'layout-header sticky' : 'layout-header'} id="layout-header">
					<div className="head container">
						
						<Logo data={company_header_logo} layout="head-logo"></Logo>

						{children}

						<div className="nav head-toggle">
                            <button type="button" className="toggle toggle-button toggle-drawer" aria-controls="layout-drawer" onClick={this.handleClick}><span><span><FormattedMessage id="button.drawer.open"/></span></span></button>
                        </div>
						
					</div>
				</header>
			)
		}
		else {

			return null;
		}
	}
}

// Get state from react redux

const mapStateToProps = (state) => ({ state: state.drawer });

// Update state in react redux

const mapDispatchToProps = (dispatch) => {

	return {

		dispatch, 

		toggleDrawer: (state) => {

			dispatch(toggleDrawer(state));
		}
	}
};

// Exporting our component via react redux

export default connect( mapStateToProps, mapDispatchToProps )(Header);
