/**
 * Showcase component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import ShowcaseArticle from './showcase-article';
import ShowcaseBanner from './showcase-banner';
import ShowcaseSwiper from './showcase-swiper';
import ShowcaseData from './showcase-data';
import ShowcaseDeliverables from './showcase-deliverables';
import ShowcaseDescription from './showcase-description';
import ShowcaseDisplay from './showcase-display';
import ShowcaseProjects from './showcase-projects';
import ShowcasePromo from './showcase-promo';
import ShowcaseMedia from './showcase-media';
import ShowcaseVideo from './showcase-video';
import ShowcaseStickyDesktop from './showcase-sticky-desktop';
import ShowcaseStickyMobile from './showcase-sticky-mobile';
import ShowcaseQuote from './showcase-quote';


export default class Showcases extends PureComponent {

	render() {
		
		const { data } = this.props;
		
		if (data.body.length) {

			return data.body.map((item, i) => {
								
				switch (item.slice_type) {

					case 'showcase_deliverables':
						return <ShowcaseDeliverables key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseDeliverables>;

					case 'showcase_description':
						return <ShowcaseDescription key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseDescription>;

					case 'showcase_display':
						return <ShowcaseDisplay key={'showcase-' + i} items={item.items} data={item.primary} label={data.domain}></ShowcaseDisplay>;
					
					case 'showcase_article':
						return <ShowcaseArticle key={'showcase-' + i} items={item.items} data={item.primary} label={data.domain}></ShowcaseArticle>;

					case 'showcase_quote':
						return <ShowcaseQuote key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseQuote>;
					
					case 'showcase_swiper':
						return <ShowcaseSwiper key={'showcase-' + i} items={item.items} data={item.primary} label={data.domain}></ShowcaseSwiper>;
					
					case 'showcase_promo':
						return <ShowcasePromo key={'showcase-' + i} items={item.items} data={item.primary}></ShowcasePromo>;

					case 'showcase_media':
						return <ShowcaseMedia key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseMedia>;

					case 'showcase_video':
						return <ShowcaseVideo key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseVideo>;

					case 'showcase_sticky_desktop':
						return <ShowcaseStickyDesktop key={'showcase-' + i} items={item.items} data={item.primary} label={data.domain}></ShowcaseStickyDesktop>;
					
					case 'showcase_sticky_mobile':
						return <ShowcaseStickyMobile key={'showcase-' + i} items={item.items} data={item.primary} label={data.domain}></ShowcaseStickyMobile>;
					
					case 'showcase_banner':
						return <ShowcaseBanner key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseBanner>;
						
					case 'showcase_data':
						return <ShowcaseData key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseData>;
					
					case 'showcase_projects':
						return <ShowcaseProjects key={'showcase-' + i} items={item.items} data={item.primary}></ShowcaseProjects>;

					default:
						return null;
				}
			})
		}
		else {

			return null;
		}
	}
}
