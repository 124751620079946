/**
 * Showcase deliverables component
 * 
 */

import React, { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

// Components

import Anchor from '../anchor/anchor';

// Functions

import Helpers from '../../utils/functions/helpers';

export default class ShowcaseDeliverables extends PureComponent {

	render() {

		const { data } = this.props;
        const visible = Helpers.isVisible(data.visible);

        let output = [];

        let timeline = '';
        let role = '';
        let delivery = '';
        let platforms = '';
        let client = '';
        let industry = '';
        let result = '';

        if (data.timeline !== null) {

            timeline = () => {
                
                return (
                    <li key="{0}">
                        <strong><FormattedMessage id="showcase.deliverables.timeline"/></strong>
                        <span>{data.timeline}</span>
                    </li>
                )
            }
        }
        else {

            timeline = () => {}
        }

        if (data.role !== null) {

            role = () => {
                
                return (
                    <li key="{1}">
                        <strong><FormattedMessage id="showcase.deliverables.role"/></strong>
                        {
                        !! data.role && data.role.split(/\n/).map((text, i) => {

                            return <span key={'role' + i}>{text}</span>;
                        })
                        }
                    </li>
                )
            }
        }
        else {

            role = () => {}
        }

        if (data.delivery !== null) {

            delivery = () => {
                
                return (
                    <li key="{2}">
                        <strong><FormattedMessage id="showcase.deliverables.delivery"/></strong>
                        {
                        !! data.delivery && data.delivery.split(/\n/).map((text, i) => {

                            return <span key={'delivery' + i}>{text}</span>;
                        })
                        }
                    </li>
                )
            }
        }
        else {

            delivery = () => {}
        }

        if (data.platforms !== null) {

            platforms = () => {
                
                return (
                    <li key="{3}">
                        <strong><FormattedMessage id="showcase.deliverables.platforms"/></strong>
                        {
                        !! data.platforms && data.platforms.split(/\n/).map((text, i) => {

                            return <span key={'platforms' + i}>{text}</span>;
                        })
                        }
                    </li>
                )
            }
        }
        else {

            platforms = () => {}
        }

        if (data.client !== null) {

            client = () => {
                
                return (
                    <li key="{4}">
                        <strong><FormattedMessage id="showcase.deliverables.client"/></strong>
                        {
                        !! data.client && data.client.split(/\n/).map((text, i) => {

                            return <span key={'client' + i}>{text}</span>;
                        })
                        }
                    </li>
                )
            }
        }
        else {

            client = () => {}
        }

        if (data.industry !== null) {

            industry = () => {
                
                return (
                    <li key="{5}">
                        <strong><FormattedMessage id="showcase.deliverables.industry"/></strong>
                        {
                        !! data.industry && data.industry.split(/\n/).map((text, i) => {

                            return <span key={'industry' + i}>{text}</span>;
                        })
                        }
                    </li>
                )
            }
        }
        else {

            industry = () => {}
        }

        if (data.result.url !== undefined) {

            result = () => {
                
                return (
                    <li key="{6}">
                        <strong><FormattedMessage id="showcase.deliverables.result"/></strong>
                        <span><Anchor link={data.result}>{data.link_label}</Anchor></span>
                    </li>
                )
            }
        }
        else {

            result = () => {}
        }

        output = [timeline(), role(), delivery(), platforms(), client(), industry(), result()];


        if (visible) {

            return (

                <div className="section">
                    <div className="container">

                        <ul className="list list-grid" data-items="4">
                            { output }
                        </ul>

                    </div>
                </div>
            )  
        }
        else {

            return '';
        }
	}
}
