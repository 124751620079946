/**
 * Section reward component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Img from '../img/img';

export default class SectionReward extends PureComponent {

	render() {
		
		const { data, items } = this.props;

		return (
		
			<div className="layout-section">
				<div className="container">

					<figure className="image"><Img data={data.image}/></figure>

					<blockquote className="blockquote is-inline">
						<p>{data.blockquote}</p>
					</blockquote>

					<figure className="image is-awards">
						{
							items.map((item, i) => {

								return (
									
									<figure key={'figure-' + i}><Img data={item.reward_logo}/></figure>
								)
							})
						}
					</figure>

				</div>
			</div>
		)
	}
}
