/**
 * Form component
 * 
 */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';

// Templates

import Contact from './templates/contact';

class Form extends PureComponent {

	render() {
		
		const { settings, device, form } = this.props;

		if (form === 'contact') {
			
			return (

				<Contact settings={settings} device={device}></Contact>
			)
		}
		else {

			return null
		}
	}
}

export default (withRouter(Form))