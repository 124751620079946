/**
 * Browser app
 * 
 */

import React from 'react';
import Loadable from 'react-loadable';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { Frontload } from 'react-frontload';
import { ConnectedRouter } from 'connected-react-router';

// Modules

import createStore from './utils/store/store';
import registerServiceWorker from './utils/modules/registerServiceWorker';

// Layout

import Prismic from './utils/prismic';

// Constants

const { store, history } = createStore();
const app = document.querySelector('#app');

const application = (

	<Provider store={store}>
		<ConnectedRouter history={history}>
			<Frontload noServerRender>
				<Prismic />
			</Frontload>
		</ConnectedRouter>
	</Provider>
);

if (process.env.NODE_ENV === 'production') {
	
	// If we're running in production, we use hydrate as an event listener
	
	Loadable.preloadReady().then(() => { hydrate(application, app); }); 
} 
else { 
	
	// Otherwise just render as normal
	
	render(application, app);
	registerServiceWorker();
}
