/**
 * Ul component
 * 
 */

import React, { PureComponent } from 'react';

export default class Ul extends PureComponent {

	render() {

		const { items, classes } = this.props;
		
		if (items) {

			return (

				<ul className={classes}>
					{
						items.split(/\n/).map((text, i) => {

	           				return <li key={'list-item' + i}>{text}</li>;
	          			})
					}
				</ul>
			)
		}
		else {

			return null;
		}
	}
}
