/**
 * Section intro component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Img from '../img/img';

export default class SectionIntro extends PureComponent {

	render() {
		
		const { data } = this.props;
		
		if (data.background && data.background.url) {

			return (

				<div className="layout-section section-intro">
					<div className="container">

						{ !! data.title && <h2>{data.title}</h2> }

					</div>
					<div className="background">

						<div className="graphic"><Img data={data.background}/></div>

					</div>
				</div>
			)
		}
		else {

			return (

				<div className="layout-section section-intro">
					<div className="container">

						{ !! data.title && <h2>{data.title}</h2> }

					</div>
				</div>
			)
		}
	}
}
