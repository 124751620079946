/**
 * Section partners component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Partners from '../partners/partners';

export default class SectionPartners extends PureComponent {

	render() {
		
		const { data, items } = this.props;
		
		return (
			
	        <div className="layout-section section-catchline">
                <div className="container">
                    
                    { !! data.title && <p className="lead">{data.title}</p> }
                    
                    <Partners items={items} columns={data.columns}></Partners>

                </div>
            </div>
		);
	}
}
