/**
 * Showcase data component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Columns from '../columns/columns';

// Functions

import Helpers from '../../utils/functions/helpers';

export default class ShowcaseData extends PureComponent {

	render() {
		
		const { data, items } = this.props;

		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			if (items.length >= 1) {

				return (

					<div className="layout-section section-showcase section-data">
						<div className="container">

						    { !! data.title && <h2>{data.title}</h2> }
							{ !! data.lead && <h3>{data.lead}</h3> }

							<Columns data={data.text}></Columns>

							<ul className="list list-columns">
							{
								items.map((item, i) => {

									return (

										<li key={'list-column-' + i}>
								            <span>{item.description}</span>
								            <strong>{item.label}</strong>
								        </li>
									)
								})
							}
							</ul>

						</div>
					</div>
				)
			}
			else {

				return (

					<div className="layout-section section-showcase section-data">
						<div className="container">

						    { !! data.title && <h2>{data.title}</h2> }
							{ !! data.lead && <h3>{data.lead}</h3> }

							<Columns data={data.text}></Columns>

						</div>
					</div>
				)
			}
		}
		else {

			return '';
		}
	}
}
