/**
 * Showcase promo component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

// Components

import Img from '../img/img';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcasePromo extends PureComponent {

	render() {
		
		const { data } = this.props;

		const alignment = ' is-' + data.alignment.toLowerCase();
		const background = ' section-' + data.background.toLowerCase();
		const classes = 'layout-section section-showcase section-promo ' + background + alignment;
		const visible = Helpers.isVisible(data.visible);
		
		if (visible) {

				return (
				
				<div className={classes}>
	                <div className="content">
	                	
	                	{ !! data.title && <h2>{data.title}</h2> }
						{ !! data.lead && <h3>{data.lead}</h3> }

						{ !! data.text && RichText.render(data.text, Helpers.linkResolver) }

	                </div>
	                <div className="graphic">
	                    
	                    <figure className="figure"><Img data={data.image}/></figure>
	                    
	                </div>
	            </div>
			)
		}
		else {

			return '';
		}
	}
}
