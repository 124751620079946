/**
 * Section services component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Button from '../button/button';
import Card from '../card/card';
import Img from '../img/img';

export default class SectionServices extends PureComponent {

	render() {
		
		const { data, items, template } = this.props;

		if (data.background && data.background.url) {

			return (

				<div className="layout-section section-center">
					<div className="container">

						{ !! data.title && <h2>{data.title}</h2> }
						{ !! data.lead && <p className="lead">{data.lead}</p> }
						{ !! data.title && <hr/> }

	                    <Card items={items} template={template} />

	                    { !! data.link && <Button url={data.link} label={data.link_label} color="primary" size="wide" /> }

					</div>
					<div className="background">

						<div className="graphic"><Img data={data.background}/></div>

					</div>
				</div>
			)
		}
		else {

			return (

				<div className="layout-section section-center">
					<div className="container">

						<h2>{data.title}</h2>
	                    <p className="lead">{data.lead}</p>
	                    <hr/>

	                    <Card items={items} template={template} />

	                    { !! data.link && <Button url={data.link} label={data.link_label} color="primary" size="wide" /> }
	                    
					</div>
				</div>
			)
		}
	}
}
