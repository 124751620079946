/**
 * Loading router
 * 
 */

import React from 'react';
	
// Layout

import Loader from '../layout/loader/loader';

export default function Loading() {

	return (
		
		<React.Fragment>
			
			<Loader>

				<div className="loader">
                    <div className="brick"></div>
                    <div className="brick"></div>
                    <div className="brick"></div>
                    <div className="brick"></div>
                </div>
				
			</Loader>
		
		</React.Fragment>
	);
}
