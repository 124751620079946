/**
 * List component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Ul from '../ul/ul';

export default class List extends PureComponent {

	render() {

		const { items } = this.props;
		
		if (items.length && items[0].item) {

			return (

				<div className="flex mb-8 d-none d-sm-flex">
					{
						items.map((item, i) => {

							return (

								<div className="item" key={'list-grid-' + i}>

									<h4 key={'list-title-' + i}>{item.title}</h4>
									<Ul items={item.item} classes="list"></Ul>

								</div>
							)
						})
					}
				</div>
			)
		}
		else {

			return null;
		}
	}
}
