/**
 * Card section component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

// Functions

import Helpers from '../../utils/functions/helpers';

// Components

import Img from '../img/img';
import Ul from '../ul/ul';

export default class CardSection extends PureComponent {

	render() {
		
		const { title, text, label, items, image, placement } = this.props.data;
		const classes = this.props.count % 2 ? 'card card-type-content is-right' : 'card card-type-content is-left';
		
		const alignment = placement.toLowerCase();
		const margin = alignment === 'left' ? 'mr-auto' : alignment === 'right' ? 'ml-auto' : '';
		
		return (

			<div className={classes}>
				<div className="content">

					{ !! title && <h3>{title}</h3> }
					{ !! text && RichText.render(text, Helpers.linkResolver) }
					{ !! label && <h6>{label}</h6> }
					
					<Ul items={items} classes="list list-cap"></Ul>

				</div>
				<figure className="graphic"><Img data={image} classes={margin} /></figure>
			</div>
		)
	}
}
