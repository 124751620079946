/**
 * Sections component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import SectionIntro from './section-intro';
import SectionServices from './section-services';
import SectionPartners from './section-partners';
import SectionTestimonial from './section-testimonial';
import SectionBlocks from './section-blocks';
import SectionTeaser from './section-teaser';
import SectionWork from './section-work';
import SectionFeatures from './section-features';
import SectionReward from './section-reward';
import SectionOutro from './section-outro';

export default class Sections extends PureComponent {

	render() {
		
		const { data } = this.props;

		if (data.body.length) {
			
			return data.body.map((item, i) => {

				switch (item.slice_type) {
					
					case 'section_intro':
						return <SectionIntro key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionIntro>;
					
					case 'section_services':
						return <SectionServices key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionServices>;
					
					case 'section_partners':
						return <SectionPartners key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionPartners>;
					
					case 'section_testimonial':
						return <SectionTestimonial key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionTestimonial>;
					
					case 'section_blocks':
						return <SectionBlocks key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionBlocks>;
					
					case 'section_teaser':
						return <SectionTeaser key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionTeaser>;
					
					case 'section_work':
						return <SectionWork key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionWork>;
					
					case 'section_features':
						return <SectionFeatures key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionFeatures>;
					
					case 'section_reward':
						return <SectionReward key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionReward>;
					
					case 'section_outro':
						return <SectionOutro key={'section-' + i} items={item.items} data={item.primary} template={item.slice_type}></SectionOutro>;
					
					default:
						return null;
				}
			});
		}
		else {

			return '';
		}
	}
}
