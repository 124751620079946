/**
 * Section outro component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Button from '../button/button';
import List from '../list/list';

export default class SectionOutro extends PureComponent {

	render() {
		
		const { data, items } = this.props;
		
		return (
			
			<div className="layout-section section-center">
				<div className="container">

					{ !! data.title && <h2>{data.title}</h2> }
					{ !! data.lead && <p className="lead">{data.lead}</p> }
					{ !! data.title && <hr/> }
					
					<List items={items}></List>

					{ !! data.link && <Button url={data.link} label={data.link_label} color="primary" size="wide" /> }

				</div>
			</div>
		);
	}
}
