/**
 * App layout
 * 
 */

import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

// Functions

import Helpers from '../utils/functions/helpers';

// Layout

import Body from './layout/body';
import Layout from './layout/layout/layout';
import Header from './layout/header/header';
import Main from './layout/main/main';
import Footer from './layout/footer/footer';
import Drawer from './layout/drawer/drawer';
import Output from './layout/output/output';
import Backdrop from './layout/backdrop/backdrop';
import Elevator from './layout/elevator/elevator';

// Meta

import Microdata from './meta/microdata/microdata';
import Analytics from './meta/analytics/analytics';

// Components

import Nav from '../components/nav/nav';

// Pages

import Home from './pages/home';
import Page from './pages/page';
import Showcase from './pages/showcase';
import NotFound from './pages/404';

// Route

const language = Helpers.getWebsiteLanguage();
const route = Helpers.getRouteURL(language);

const App = (props) => (
	
	<Body>
		<Layout>

			<Header settings={props.settings}>
				<Nav uid="nav-desktop" className="nav-links nav-header" layout="head-menu" nav={props.navigation}></Nav>
			</Header>

			<Main>
				<Switch>
					<Redirect exact from="/home" to="/" />
					<Route exact path="/" render={routeProps => <Home {...routeProps} cms={props.cms} settings={props.settings} device={props.device} />} />
					<Route exact path="/:uid" render={routeProps => <Page {...routeProps} cms={props.cms} settings={props.settings} device={props.device} />} />
					<Route exact path={route + ':uid'} render={routeProps => <Showcase {...routeProps} cms={props.cms} settings={props.settings} device={props.device} />} />
		            <Route component={NotFound} />
				</Switch>
			</Main>
			
			<Footer settings={props.settings}>
				<Nav uid="nav-footer-explore" className="nav-tree nav-footer" layout="" nav={props.navigation}></Nav>
				<Nav uid="nav-footer-solutions" className="nav-tree nav-footer" layout="" nav={props.navigation}></Nav>
				<Microdata render="company" settings={props.settings}></Microdata>
			</Footer>

			<Route component={Analytics} />

		</Layout>
		<Drawer settings={props.settings}>
			<Nav uid="nav-footer-explore" className="nav-tree nav-drawer" layout="drawer-menu" nav={props.navigation}></Nav>
			<Nav uid="nav-footer-solutions" className="nav-tree nav-drawer" layout="drawer-menu" nav={props.navigation}></Nav>
		</Drawer>
		<Output></Output>
		<Backdrop></Backdrop>
		<Elevator></Elevator>
	</Body>
);

export default App;
