/**
 * Section blocks component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Blocks from '../blocks/blocks';

export default class SectionBlocks extends PureComponent {

	render() {
		
		const { data, items } = this.props;

		return (
			
			<div className="layout-section">
				<div className="container">

					{ !! data.title && <h2>{data.title}</h2> }
					{ !! data.lead && <p className="lead">{data.lead}</p> }

					<Blocks items={items} />

				</div>
			</div>
		);
	}
}
