/**
 * Showcase media component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Columns from '../columns/columns';
import Img from '../img/img';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcaseMedia extends PureComponent {

	render() {
		
		const { data } = this.props;
		
		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			return (
			
				<div className="layout-section section-showcase section-media">
					<div className="container">

						{ !! data.title && <h2>{data.title}</h2> }
						{ !! data.lead && <h3>{data.lead}</h3> }

						<Columns data={data.text}></Columns>

						<figure className="image"><Img data={data.image}/></figure>

					</div>
				</div>
			)
		}
		else {

			return '';
		}
	}
}
