/**
 * Footer component
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';
import { RichText } from 'prismic-reactjs';

// Functions

import Helpers from '../../../utils/functions/helpers';

// Components

import Logo from '../../../components/logo/logo';
import Social from '../../../components/social/social';

export default class Footer extends React.PureComponent {

	static propTypes = {
		
		children: PropTypes.node,
	}
	
	render() {
		
		const { children, settings } = this.props;

		if (settings) {

			const { company_address, company_footer_logo, tagline_text, copyright_text } = settings;
			
			return (

				<footer className="layout-footer" id="layout-footer">

					<div className="foot foot-menus container">
						
						<div className="row">
							<div className="col">

								<Logo data={company_footer_logo} layout="foot-logo"></Logo>

								<span className="tagline">{tagline_text}</span>
	                            <address className="address">{company_address}</address>

	                            <Social data={settings}></Social>

							</div>
							<div className="col">

								{ React.Children.map(children, (child, i) => { if (i === 0) return child }) }

							</div>
							<div className="col">

								{ React.Children.map(children, (child, i) => { if (i === 1) return child }) }

							</div>
						</div>

					</div>

					<div className="foot foot-terms container">
						{ !! copyright_text && RichText.render(copyright_text, Helpers.linkResolver, Helpers.htmlSerializerToSpan) }
					</div>

					{ React.Children.map(children, (child, i) => { if (i === 2) return child }) }

				</footer>
			)
		}
		else {

			return null;
		}
	}
}
