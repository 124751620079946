/**
 * Card widget component
 * 
 */

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

// Functions

import Helpers from '../../utils/functions/helpers';
import Tracking from '../../utils/functions/analytics';

// Components

import Img from '../img/img';

export default class CardWidget extends PureComponent {

	constructor(props) {

		super(props);
		
    	this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(event) {
		
		Tracking.navigation({ action: 'widget', label: event.currentTarget.getAttribute('aria-label') });
	}

	render() {

		const { title, text, link, image, placement } = this.props.data;
		
		const url = Helpers.linkResolver(link);

		let alignment = '';
		let margin = 'cover';
		
		if (placement) {

			alignment = placement.toLowerCase();
			margin = alignment === 'left' ? 'mr-auto' : alignment === 'right' ? 'ml-auto' : '';
		}

		if (url === undefined) {

			return (

				<section className="card card-type-widget">
					<figure className="graphic"><Img data={image} classes={margin} /></figure>
					<div className="content">
						<h3>{title}</h3>
						<p>{text}</p>
					</div>
				</section>
			)
		}
		else {

			return (

				<section className="card card-type-widget">
					<Link to={url} className="anchor" aria-label={title} onClick={this.handleClick}>
						<figure className="graphic"><Img data={image} classes={margin} /></figure>
						<div className="content">
							<h3>{title} </h3>
							<p>{text}</p>
						</div>
					</Link>
				</section>
			)
		}
	}
}
