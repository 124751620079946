/**
 * Showcase router
 * 
 */

import React from 'react';

// Functions
import Helpers from '../../utils/functions/helpers';

// Components
import Hero from '../../components/hero/hero';
import Article from '../../components/article/article';
import Columns from '../../components/columns/columns';
import Showcases from '../../components/showcase/showcase';

// Meta
import Head from '../meta/head/head';
import Theme from '../meta/theme/theme';

// Pages
import NotFound from './404';
import Loading from './loading';

export default class Showcase extends React.Component {

	constructor(props) {

		super(props);

		this.state = {

			doc: null,
			template: null,
			settings: null,
			notFound: false
		}
	}

	componentDidMount() {

		this.updatePage();
	}

	componentDidUpdate(prevProps, prevState) {

		if (this.props.match.url !== prevProps.match.url) {

			this.setState({ doc: null });
			this.updatePage();
		}
		else {

			this.updatePage();
		}
	}

	updatePage() {

		if (this.props.settings !== null) {

			const { settings } = this.props;
			const url = this.props.match.params.uid === undefined ? '' : this.props.match.params.uid;
			
			if (this.state.doc === null) {

				return this.props.cms.api.getByUID('showcase', url, {}, (err, doc) => {

					if (doc) {
						
						// Renaming object keys to support scalable templates
						Helpers.renameObjectKeys(doc.data.body);

						this.setState({ doc: doc, settings: settings, template: doc.type });
					}
					else {

						this.setState({ doc: this.props.match.url, settings: settings, template: '', notFound: true });
					}
				});
			}
			else {

				return null;
			}
		}

		return null;
	}

	render() {

		if (this.state.doc) {

			const { data } = this.state.doc;
			const { settings, template } = this.state;

			if (template === 'showcase') {

				return (

					<React.Fragment>

						<Head data={data} settings={settings}></Head>
						<Theme theme={data.theme_class}></Theme>

						<Hero data={data} layout="aside"></Hero>
						
						<Article layout="full">

							<div className="layout-section">
								<div className="container">

									<h1>{data.title[0].text}</h1>
									<p className="lead">{data.lead}</p>

									<Columns data={data.introduction}></Columns>
									
								</div>
							</div>

							<Showcases data={data}></Showcases>

						</Article>

					</React.Fragment>
				)
			}
			else {

				return <NotFound location={this.props.location} settings={this.props.settings} />
			}
		}
		else if (this.state.notFound) {

			return <NotFound location={this.props.location} settings={this.props.settings} />
		}

		return <Loading />
    }
}