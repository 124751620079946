/**
 * Textarea component
 * 
 */

import React, { PureComponent } from 'react';

export default class Textarea extends PureComponent {

	static displayName = 'textarea';

	constructor(props) {

		super(props);
		
    	this.state = {

    		value: props.value,
    		data: { label: props.children[0], name: props.name, id: props.id + '-' + props.name, type: 'textarea', required: props.required, value: props.value }
    	};
    	
    	this.props.update(this.state.data);
    	this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {

		const target = event.target;
		const group = target.closest('.form-group');
		const value = target.value;

		let temp = this.state.data;

		temp.value = value;

		this.setState({ value: value, data: temp });
		this.props.update(this.state.data);

		target.classList.remove('is-invalid');
		group.classList.remove('invalid');
	}

	clear() {
		
		let temp = this.state.data;

		temp.value = '';
		
		this.setState({ value: '', data: temp });
	}

	render() {

		const { children: text, label, placeholder } = this.props;

		const id = this.state.data.id;
		const name = this.state.data.name;
		const required = this.state.data.required;

		const holder = placeholder ? {'placeholder': placeholder} : {};
		const screenreader = label === 'true' ? {} : { 'className': 'sr-only' };
		const rows = this.propsrows ? this.propsrows : 8;
						
		return (

			<div className="form-group">
				<label {...screenreader} htmlFor={id}>{text}</label>
				<textarea className="form-control" name={name} id={id} rows={rows} autoComplete="off" spellCheck="false" aria-required={required} aria-invalid="false" {...holder} onChange={this.handleChange} value={this.state.value}></textarea>
			</div>
		)
	}
}
