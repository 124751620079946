/**
 * Layout hero component
 * 
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Hero extends PureComponent {

	static propTypes = {
		
		children: PropTypes.node,
	}

	render() {
		
		const { data, layout } = this.props;

		if (!! data.lead && !! data.lead[0] && layout !== 'aside') {

			return (

				<div className="layout-hero hero-type-highlight">
					<div className="container">

						<h1>{data.lead[0].text}</h1>

						<div className="pattern pattern-a"></div>
						<div className="pattern pattern-b"></div>
						<div className="pattern pattern-c"></div>
						<div className="pattern pattern-d"></div>

					</div>
				</div>
			)
		}
		else {

			return (

				<aside className="layout-hero hero-type-highlight" aria-hidden="true">

					<div className="pattern pattern-a"></div>
					<div className="pattern pattern-b"></div>
					<div className="pattern pattern-c"></div>
					<div className="pattern pattern-d"></div>
					
				</aside>
			)
		}
	}
}
