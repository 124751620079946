/**
 * Showcase description component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Columns from '../columns/columns';

// Functions

import Helpers from '../../utils/functions/helpers';

export default class ShowcaseDescription extends PureComponent {

	render() {
		
		const { data } = this.props;
		
		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			return (

				<div className="layout-section section-showcase section-description">
					<div className="container">

						{ !! data.title && <h2>{data.title}</h2> }
						{ !! data.lead && <h3>{data.lead}</h3> }

						<Columns data={data.text}></Columns>

					</div>
				</div>
			)
		}
		else {

			return '';
		}
	}
}
