/**
 * Showcase projects component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Button from '../button/button';
import Card from '../card/card';

// Functions

import Helpers from '../../utils/functions/helpers';

export default class ShowcaseProjects extends PureComponent {

	render() {
		
		const { data, items } = this.props;
		const visible = Helpers.isVisible(data.visible);

		if (items.length >= 1 && visible) {

			return (

	            <div className="layout-section section-center">
					<div className="container">

						{ !! data.title && <h2>{data.title}</h2> }
						<hr/>

						<Card items={items} template={'section_projects'} />
						
						{ !! data.link && <Button url={data.link} label={data.label} color="outline-primary btn-center" /> }

					</div>
				</div>
	        )
		}
		else {

			return null;
		}
	}
}
