/**
 * Content text component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

export default class ContentText extends PureComponent {

	render() {
		
		const { data } = this.props;

		return (
			
			<React.Fragment>
	        	
	        	{ RichText.render(data.content) }
	        	
	        </React.Fragment>
		);
	}
}
