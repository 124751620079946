/**
 * Javascript analytics functions
 *
 */

import ReactGA from 'react-ga';

const Tracking = {

	navigation: (event) => {
		
		if (global.Analytics && global.Analytics.includes('google')) {

			ReactGA.event({ category: 'Navigation', action: 'Clicked ' + event.action.charAt(0).toUpperCase() + event.action.slice(1), label: event.label });
		}
	},

	outbound: (event) => {

		if (global.Analytics && global.Analytics.includes('google')) {

			ReactGA.outboundLink({ label: event.label }, function () {});
		}
	},

	page: (path) => {

		// Google analytics

		if (global.Analytics && global.Analytics.includes('google')) {

			ReactGA.set({ page: path });
			ReactGA.pageview(path);
		}

		// Facebook pixel

		if (window.fbq) {
			
			window.fbq('track', 'PageView');
		}

		// Twitter pixel

		if (window.twq) {
			
			window.twq('track', 'PageView');
		}
	}
}

export default Tracking