/**
 * Content card component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

// Functions

import Helpers from '../../utils/functions/helpers';

// Components

import Img from '../img/img';

export default class ContentCard extends PureComponent {

	render() {
		
		const { data } = this.props;

		const alignment = data.alignment.toLowerCase();
		const classes = { 'className': 'card card-type-content is-' + alignment};

		const placement = data.content_image_placement.toLowerCase();
		const margin = placement === 'left' ? 'mr-auto' : placement === 'right' ? 'ml-auto' : '';
				
		return (
			
			<div {...classes}>
                <div className="content">

                    { RichText.render(data.text, Helpers.linkResolver) }

                </div>
                <figure className="graphic"><Img data={data.image} classes={margin} /></figure>
            </div>
		);
	}
}
