/**
 * Redux store functions
 *
 */

import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory, createMemoryHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

// Let's include our reducers

import createRootReducer from '../reducers/reducers';

// Let's initialize our redux store functions

export const isServer = !( typeof window !== 'undefined' && window.document && window.document.createElement );

export default (url = '/') => {

	const history = isServer ? createMemoryHistory({ initialEntries: [url] }) : createBrowserHistory();
	const enhancers = [];

	if (process.env.NODE_ENV === 'development' && !isServer) {
	
		const devToolsExtension = window.devToolsExtension;

		if (typeof devToolsExtension === 'function') { 
			
			enhancers.push(devToolsExtension());
		}
	}

	const middleware = [thunk, routerMiddleware(history)];
	const composedEnhancers = compose( applyMiddleware(...middleware), ...enhancers );
	const initialState = !isServer ? window.__PRELOADED_STATE__ : {};

	if (!isServer) {
		
		delete window.__PRELOADED_STATE__;
	}

	const store = createStore( createRootReducer(history), initialState, composedEnhancers );

	return { store, history };
};
