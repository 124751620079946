/**
 * Project prismic configuration
 *
 */

export default {

	// Public API

	apiEndpoint: {'en': 'https://abstractic-com.cdn.prismic.io/api/v2', 'sv': 'https://abstractic-se.cdn.prismic.io/api/v2'},

	// Private API

	accessToken: {'en': 'MC5YNVBwMUJBQUFDQUFiVWM2.77-977-977-977-977-977-977-977-9eR_vv71b77-977-977-9QAsd77-977-977-977-9Hu-_ve-_ve-_vUYVCUogVA', 'sv': 'MC5YNVBwMEJFQUFDQUFtVWNU.Qe-_vQ52MmQtQO-_vQd377-9V--_ve-_vWIH77-9SHhc77-977-9We-_ve-_ve-_ve-_vUTvv73vv70u'},
	clientId: {'en': 'X5Pp1BAAACIAbUc5', 'sv': 'X5Pp0BEAAB4AmUcS'},
	clientSecret: {'en': '9622bcfbcf180e79100a88e578ced953', 'sv': '1917440896a5af1a7f0f59ef6bb04736'}
	
};
