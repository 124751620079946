/**
 * Showcase banner component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Img from '../img/img';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcaseBanner extends PureComponent {

	render() {
		
		const { data } = this.props;

		const styling = data.styling === 'Default' ? '' : 'section-' + data.styling.toLowerCase();
		const inline = data.inline === true ? 'section-banner-inline' : '';
		const classes = 'layout-section section-showcase section-banner ' + styling + ' ' + inline ;
		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			return (

				<div className={classes}>
					<div className="container">

						<figure className="image"><Img data={data.image}/></figure>

					</div>
				</div>
			)
		}
		else {

			return '';
		}
	}
}
