/**
 * Output component
 * 
 */

import React from 'react';

export default class Output extends React.PureComponent {

	constructor(props) {
		
		super(props);

		this.state = {

			visible: false
		}
    }
	
	render() {
		
		const { visible } = this.state;

		return (

			<div className={visible ? 'layout-output show' : 'layout-output'} id="layout-output" aria-hidden="true"></div>
		)
	}
}
