/**
 * Anchor component
 * 
 */

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

// Functions

import Helpers from '../../utils/functions/helpers';
import Tracking from '../../utils/functions/analytics';

export default class Anchor extends PureComponent {

	constructor(props) {

		super(props);
		
    	this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(event) {
		
		Tracking.navigation({ action: 'link', label: event.target.text });
	}

	render() {

		const { link, children: label, target, rel } = this.props;

		const url = link.type === undefined ? link.url : Helpers.linkResolver(link);
		const targeting = target === undefined ? link.link_type === 'Document' ? {} : { target: '_blank' } : { target: target };
		const relation = rel === undefined ? link.link_type === 'Web' ? { rel: 'noopener noreferrer' } : {} : { rel: rel };
		
		if (url && label) {

			if (link.type === undefined) {

				return <a href={url} className="link" {...relation} {...targeting} onClick={this.handleClick}>{label}</a>;
			}
			else {

				return <Link to={url} className="link" {...relation} {...targeting} onClick={this.handleClick}>{label}</Link>;
			}
		}
		else {

			return null;
		}
	}
}
