/**
 * Article component
 * 
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Article extends PureComponent {

	static propTypes = {
		
		children: PropTypes.node,
	}

	render() {
		
		const { children, layout } = this.props;
				
		if (layout === 'full') {

			return (
				
		        <article className="layout-article">

					{children}
				
				</article>
			);
		}
		else {
			
			return (
				
		        <article className="layout-article">

		        	<div className="article">

		        		{ React.Children.map(children, (child, i) => { if (i === 0) return child }) }

			        	<div className="content container">
							<div className="content-full">
								
								{ React.Children.map(children, (child, i) => { if (i >= 1) return child }) }

							</div>
						</div>

					</div>
					
				</article>
			);
		}
	}
}
