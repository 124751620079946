/**
 * Partners component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Img from '../img/img';

export default class Partners extends PureComponent {

	render() {

		const { items, columns } = this.props;
		
		if (items.length && items[0].name) {

			const column = columns.substr(0, columns.indexOf(' '));

			let grid = '';

			switch (column) {

				case '1':

					grid = { 'data-items': '1' };

				break;
				case '2':

					grid = { 'data-items': '2' };

				break;
				case '3':

					grid = { 'data-items': '3', 'data-sm-items': '2' };

				break;
				case '4':

					grid = { 'data-items': '4', 'data-md-items': '2' };

				break;
				case '5':

					grid = { 'data-items': '5', 'data-md-items': '2' };

				break;
				case '6':

					grid = { 'data-items': '6', 'data-md-items': '3', 'data-sm-items': '2' };

				break;
				default:

					grid = { 'data-items': '3', 'data-md-items': '2' };

				break;
			}
			
			return (

				<ul className="stack stack-matrix" {...grid}>
					{
						items.map((item, i) => {

							return <li className="item" key={'list-' + i}><Img data={item.logo} classes="contain"/></li>
						})
					}
				</ul>
			)
		}
		else {
	
			return null;			
		}
	}
}
