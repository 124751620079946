/**
 * Prismic router
 * 
 */

import React from 'react';
import { withRouter } from 'react-router';
import { IntlProvider } from 'react-intl';
import ReactPrismic from 'prismic-javascript';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

// Webpack

import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// Functions

import Helpers from './functions/helpers';
import Config from './config/prismic';

// Containers

import App from '../containers/app';
import Scroll from '../containers/layout/scroll/scroll';

// Localization

import english from './localization/en.json';
import swedish from './localization/sv.json';

class Prismic extends React.Component {
	
	constructor(props) {

		super(props);

		const language 		= typeof window !== 'undefined' ? document.documentElement.lang : 'en';
		const languages 	= { en: english, sv: swedish };
		const localization 	= { default: 'en', messages: languages };
		const translation 	= { language: language, default: localization.default, messages: localization.messages[language] };

		this.state = {
			
			prismicCtx: null,
			navigation: null,
			settings: null,
			device: null,
			language: language,
			languages: languages,
			localization: localization,
			translation: translation,
		}
	}

	componentDidMount() {

		this.buildContext().then((prismicCtx) => { this.setState({ prismicCtx }); }).catch((e) => { console.error(`Cannot contact the API, check your prismic configuration:\n${e}`); });
	}

	getNavigation(api) {

		api.query([ReactPrismic.Predicates.at('document.type', 'menu')]).then(response => { this.setState({ navigation: response.results }); });
	}

	getWebsiteSettings(api) {
		
		api.query([ReactPrismic.Predicates.at('document.type', 'settings')]).then(response => { this.setState({ settings: response.results }); this.setWebsiteTracking(); });
	}

	setWebsiteTracking() {

		if (process.env.NODE_ENV !== 'development') {

			const google = this.state.settings[0].data.google_analytics_id;
			const facebook = this.state.settings[0].data.facebook_pixel;

			let tracking = [];

			if (google) {

				ReactGA.initialize(google.toString());
				tracking.push('google');
			}

			if (facebook) {

				ReactPixel.init(facebook.toString());
				tracking.push('facebook');
			}

			global.Analytics = tracking;
		}
	}

	getDeviceSettings() {
		
		const browser = navigator.userAgent;

		Helpers.publicIP().then(ip => { this.setState({ device: { ip: ip, browser: browser } }); });
	}

	buildContext() {

		const language = this.state.language;
		const accessToken = Config.accessToken[language];

    	return ReactPrismic.api(Config.apiEndpoint[language], { accessToken })
			
			.then(api => ({ 
				
    			api, 
    			accessToken, 
    			endpoint: Config.apiEndpoint[language], 
    			linkResolver: Helpers.linkResolver, 
    			navigation: this.getNavigation(api), 
    			settings: this.getWebsiteSettings(api),
    			device: this.getDeviceSettings()
			})
		);
	}

	render() {
						
		return (
			
			<IntlProvider locale={this.state.language} defaultLocale={this.state.localization.default} messages={this.state.localization.messages[this.state.language]}>
				<Scroll>
					<App cms={this.state.prismicCtx} navigation={this.state.navigation} device={this.state.device} settings={this.state.settings && this.state.settings.length && this.state.settings[0].data} />
				</Scroll>
			</IntlProvider>
		);
	}
}

export default withRouter(Prismic);
