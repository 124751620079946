/**
 * Page router
 * 
 */

import React from 'react';
import Prismic from 'prismic-javascript';

// Functions
import Helpers from '../../utils/functions/helpers';

// Components
import Hero from '../../components/hero/hero';
import Article from '../../components/article/article';
import Section from '../../components/section/section';
import Content from '../../components/content/content';

// Form
import Form from '../../form/form';

// Meta
import Head from '../meta/head/head';
import Theme from '../meta/theme/theme';

// Pages
import NotFound from './404';
import Loading from './loading';

export default class Page extends React.Component {

	constructor(props) {

		super(props);

		this.state = {

			doc: null,
			device: null,
			template: null,
			settings: null,
			notFound: false
		}
	}

	componentDidMount() {

		this.updatePage();
	}

	componentDidUpdate(prevProps, prevState) {

		if (this.props.match.url !== prevProps.match.url) {

			this.setState({ doc: null });
			this.updatePage();
		}
		else {

			this.updatePage();
		}
	}

	updatePage() {
		
		if (this.props.settings != null) {

			const { settings, device } = this.props;
			const url = this.props.match.params.uid === undefined ? '' : this.props.match.params.uid;

			if (this.state.doc === null) {

				this.props.cms.api.query(Prismic.Predicates.any('document.type', ['page', 'article', 'contact'])).then(function(response) {

					const doc = response.results.find(f => f.uid === url);

					if (doc) {

						// Renaming object keys to support scalable templates
						Helpers.renameObjectKeys(doc.data.body);
						
						this.setState({ doc: doc, settings: settings, device: device, template: doc.type });
					}
					else {

						this.setState({ doc: this.props.match.url, settings: settings, device: device, template: '', notFound: true });
					}

				}.bind(this));
			}
			else {

				return null;
			}
		}

		return null;
	}

	render() {

		if (this.state.doc) {

			const { data } = this.state.doc;
			const { settings, template, device } = this.state;
			
			if (template === 'page') {

				return (

					<React.Fragment>
						
						<Head data={data} settings={settings}></Head>
						<Theme theme={data.theme_class}></Theme>

						<Article layout="full">

							<Hero data={data}></Hero>
							<Section data={data}></Section>

						</Article>

					</React.Fragment>
				)
			}
			else if (template === 'article') {

				return (

					<React.Fragment>
						
						<Head data={data} settings={settings}></Head>
						<Theme theme={data.theme_class}></Theme>

						<Hero data={data} layout="aside"></Hero>

						<Article layout="full">

							<div className="layout-section">
								<div className="container">

									<h1>{data.title[0].text}</h1>
									{ !! data.lead && <p className="lead">{data.lead}</p> }

									<Content data={data}></Content>
									
								</div>
							</div>

						</Article>

					</React.Fragment>
				)
			}
			else if (template === 'contact') {

				return (

					<React.Fragment>
						
						<Head data={data} settings={settings}></Head>
						<Theme theme={data.theme_class}></Theme>

						<Hero data={data} layout="aside"></Hero>

						<Article layout="full">
							
							<div className="layout-section">
								<div className="container">

									<h1>{data.title[0].text}</h1>
									{ !! data.lead && <p className="lead">{data.lead}</p> }

									<Form settings={settings} device={device} form="contact"></Form>
									
								</div>
							</div>

						</Article>

					</React.Fragment>
				)
			}
			else {

				return <NotFound location={this.props.location} settings={this.props.settings} />
			}
		}
		else if (this.state.notFound) {

			return <NotFound location={this.props.location} settings={this.props.settings} />
		}

		return <Loading />
    }
}