/**
 * Layout component
 * 
 */

import React from 'react';
import PropTypes from 'prop-types';

export default class Layout extends React.PureComponent {

	static propTypes = {
		
		children: PropTypes.node,
	}

	render() {
		
		const { children } = this.props;

		return (
			
	        <div className="layout" id="layout">
				{children}
			</div>
		);
	}
}
