/**
 * Showcase quote component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcaseQuote extends PureComponent {

	render() {
		
		const { data } = this.props;

		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			return (

				<div className="layout-section section-showcase section-quote">
					<div className="container">

						<hr/>

		                <blockquote className="blockquote is-inline">
		                    { !! data.text && RichText.render(data.text, Helpers.linkResolver) }
		                </blockquote>

					</div>
				</div>
			)
		}
		else {

			return '';
		}
	}
}
