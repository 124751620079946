/**
 * Button component
 * 
 */

import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

// Functions

import Helpers from '../../utils/functions/helpers';
import Tracking from '../../utils/functions/analytics';

export default class Button extends PureComponent {

	constructor(props) {

		super(props);
		
    	this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(event) {
		
		Tracking.navigation({ action: 'button', label: event.target.text });
	}

	render() {

		const { url, label, color, size } = this.props;

		const classes = 'btn' + (color === undefined ? ' btn-primary' : ' btn-' + color) + (size === undefined || size === '' ? '' : ' btn-' + size);

		if (url && label) {

			if (url.isBroken === false) {

				const href = Helpers.linkResolver(url);

				return <Link to={href} className={classes} onClick={this.handleClick}>{label}</Link>;
			}
			else {

				return <button type="button" className={classes} onClick={this.handleClick}>{label}</button>;
			}
		}
		else {
						
			return null;
		}
	}
}
