/**
 * Showcase video component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Columns from '../columns/columns';
import Img from '../img/img';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcaseVideo extends PureComponent {

	render() {
		
		const { data } = this.props;
		
		const inline = data.inline === true ? 'section-media-inline' : '';
		const classes = 'layout-section section-showcase section-media section-video ' + inline ;
		const visible = Helpers.isVisible(data.visible);
		const video = data.media.url;

		const ratio = data.height / data.width;
		const aspect = ratio.toFixed(2) === '2.17' ? '375x812' : '9x16';
		
		if (visible) {

			return (
			
				<div className={classes}>
					<div className="container">

						{ !! data.title && <h2>{data.title}</h2> }
						{ !! data.lead && <h3>{data.lead}</h3> }

						<Columns data={data.text}></Columns>

						<figure className={'video video-wrapper ratio ratio--' + aspect}>
							<video width={data.width} height={data.height} playsInline loop autoPlay muted>
							<source src={video} type="video/mp4" />
							</video>
							{ !! data.image.url && <div className="wrapper"><Img data={data.image} expand="800" /></div> }
						</figure>

					</div>
				</div>
			)
		}
		else {

			return '';
		}
	}
}
