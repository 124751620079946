/**
 * Showcase sticky component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

// Layout

import Browser from '../browser/browser';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcaseStickyDesktop extends PureComponent {

	render() {
		
		const { data, items, label } = this.props;
		
		const alignment = ' is-' + data.alignment.toLowerCase();
		const classes = 'layout-section section-showcase section-sticky ' + alignment;
		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			return (

				<div className={classes}>
					<div className="container">
						<div className="content">

							{ !! data.title && <h2>{data.title}</h2> }
							{ !! data.lead && <h3>{data.lead}</h3> }

							{ !! data.text && RichText.render(data.text, Helpers.linkResolver) }

						</div>
						<div className="graphic">
							
							{
								items.map((item, i) => {
									
									return <Browser key={'browser-' + i} image={item.image} label={label} size={'md'}></Browser>;
								})
							}

						</div>
					</div>
				</div>
			)
		}
		else {

			return '';
		}
	}
}
