/**
 * Section teaser component
 * 
 */

import React, { PureComponent } from 'react';

// Functions
import Helpers from '../../utils/functions/helpers';

// Components

import Button from '../button/button';
import Img from '../img/img';

export default class SectionTeaser extends PureComponent {

	render() {
		
		const { data } = this.props;

		const alignment = 'is-' + data.alignment.toLowerCase();
		const classes = 'layout-section section-teaser ' + alignment;
		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			return (
			
				<div className={classes}>
					<div className="content">

						{ !! data.title && <h2>{data.title}</h2> }
						{ !! data.lead && <h3>{data.lead}</h3> }
						{ !! data.text && <p>{data.text}</p> }

						{ !! data.link && <Button url={data.link} label={data.link_label} color="primary" size="" /> }

					</div>
					<div className="graphic">

						<figure className="figure"><Img data={data.image}/></figure>

					</div>
				</div>
			);
		}
		else {

			return '';
		}
	}
}
