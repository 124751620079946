/**
 * Section features component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Card from '../card/card';

export default class SectionFeatures extends PureComponent {

	render() {
		
		const { data, items, template } = this.props;
		
		return (
			
			<div className="layout-section section-center">
				<div className="container">

					{ !! data.title && <h2>{data.title}</h2> }
					{ !! data.lead && <p className="lead">{data.lead}</p> }
					{ !! data.title && <hr/> }

					<Card items={items} template={template} />
					
				</div>
			</div>
		);
	}
}
