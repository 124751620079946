/**
 * Rendering component
 * 
 */

import React, { PureComponent } from 'react';
import { IntlProvider } from 'react-intl';
import Scroll from 'react-scroll';
import ReactDOM from 'react-dom';
import validator from 'validator';

// Components

import Output from '../../components/output/output';

// Validation

import Errors from '../validation/errors';
import Feedback from '../validation/feedback';
import Helpers from '../validation/helpers';

export default class Rendering extends PureComponent {

	constructor(props) {

        super(props);
        
        this.state = {

            data: []
        }

        this.components = {};
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUpdate(data) {
        
        let temp = this.state.data;
        let index = temp.findIndex(obj => obj.name === data.name);

        if (index >= 0) {

            temp[index] = data;
        }
        else {

            temp.push(data);
        }

        this.setState({ data: temp });
    }
    
    handleSubmit(event) {

        event.preventDefault();

        const target = event.target;
        const button = target.querySelector('button[type=submit]');
        const loader = document.createElement('strong');
        const output = document.getElementById('layout-output');
        const error = this.handleValidation();

        output.classList.remove('show');
        ReactDOM.unmountComponentAtNode(output);
        this.handleLoading(button, loader);
                
        setTimeout(() => {

            this.handleLoading(button, loader, 'remove');

            if (error.length >= 1) {

                this.handleError(error, output);
            }
            else {

                this.handleSuccess(target, output);
            }

        }, 1000);
    }

    handleValidation() {

        let error = [];
        let valid = '';

        this.state.data.forEach(function(field) {

            if (field.required === 'true' && field.value === '') {

                error.push({ field: field.id, type: field.type, value: field.value, feedback: Errors.empty(field.label) });
            }

            if (field.value !== '') {

                switch (field.type) {

                    case 'email':

                        valid = validator.isEmail(field.value);

                        if (valid === false) {

                            error.push({ field: field.id, type: field.type, value: field.value, feedback: Errors.email(field.label) });
                        }
                        else {

                            const unicode = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

                            valid = unicode.test(field.value);

                            if (valid === false) {

                                error.push({ field: field.id, type: field.type, value: field.value, feedback: Errors.unicode(field.label) });
                            }
                        }
                        
                        break;
                    case 'url':

                        valid = validator.isURL(field.value);

                        if (valid === false) {

                            error.push({ field: field.id, type: field.type, value: field.value, feedback: Errors.url(field.label) });
                        }

                        break;
                    case 'tel':

                        if (field.value.startsWith('+')) {

                            valid = validator.isMobilePhone(field.value.replace('+', ''), 'any');
                            
                            if (valid === false) {

                                error.push({ field: field.id, type: field.type, value: field.value, feedback: Errors.tel(field.label) });
                            }
                        }
                        else {

                            error.push({ field: field.id, type: field.type, value: field.value, feedback: Errors.international(field.label) });
                        }
                        
                        break;
                    default:
                        
                        break;
                }
            }
        });

        return error;
    }

    handleError(error, output) {

        Helpers.removeClass(document.getElementsByClassName('form-group'), 'invalid');
        Helpers.removeClass(document.getElementsByClassName('form-control'), 'is-invalid');
        Helpers.removeClass(document.getElementsByTagName('input'), 'is-invalid');

        error.forEach(function(item) {

            const group = document.getElementById(item.field);

            if (item.type === 'radio' || item.type === 'checkbox') {

                group.classList.add('invalid');
                Helpers.addClass(group.getElementsByTagName('input'), 'is-invalid');
            }
            else {

                group.classList.add('is-invalid');
                group.closest('.form-group').classList.add('invalid');
            }
        });
        
        const offset = Helpers.offset(error[0].field);
        const position = offset.top - 100;

        Scroll.animateScroll.scrollTo(position);

        ReactDOM.render(<IntlProvider locale="en"><Output alert="danger">{error[0].feedback}</Output></IntlProvider>, output);
        output.classList.add('show');
    }

    handleLoading(button, loader, loading = 'add') {

        if (loading === 'add') {

            loader.classList.add('loading');
            loader.appendChild(document.createElement('span'));
            loader.appendChild(document.createElement('span'));
            loader.appendChild(document.createElement('span'));

            button.classList.add('process');
            button.setAttribute('disabled', 'disabled');
            button.appendChild(loader);
        }
        else {

            button.removeChild(loader);
            button.classList.remove('process');
            button.removeAttribute('disabled');
        }
    }

    handleEmail() {

        const spam = [];
        const data = [];
        const sender = [];

        const firstname = this.state.data.filter(obj => obj.name === 'firstname');
        const lastname = this.state.data.filter(obj => obj.name === 'lastname');
        const email = this.state.data.filter(obj => obj.name === 'email');
        const message = this.state.data.filter(obj => obj.name === 'message');
        const name = this.state.data.filter(obj => obj.name === 'name');

        sender.push({ name: name[0] ? name[0].value : firstname[0].value + ' ' + lastname[0].value, email: email[0].value });

        this.state.data.forEach(function(content) { 

            if (content.type === 'hidden' || content.type === 'captcha') {

                if (content.type === 'captcha' && content.value !== '') {

                    spam.push({ ip: this.props.device.ip, browser: this.props.device.browser, page: window.location.href, author: sender[0].name, email: sender[0].email, captcha: content.value, content: message[0] ? message[0].value : '' });
                }
            }
            else {

                data.push({ label: content.label, value: content.value, type: content.type });
            }
        });
        
        const proxy = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_DEV_PROXY + ':' + process.env.REACT_APP_DEV_PORT : process.env.REACT_APP_WEB_PROXY + ':' + process.env.REACT_APP_WEB_PORT;

        fetch(proxy + '/email', { method: 'POST', headers: { Accept: 'application/json', 'Content-Type': 'application/json', }, body: JSON.stringify({ data: data, spam: spam, sender: sender, settings: this.props.settings, domain: window.location.href.split('/').slice(0, 3).join('/') }) })
        .then((response) => response.json())
        .then((success) => {})
        .catch((error) => { console.log(error) });
    }

    handleReset(target, output) {

        Object.values(this.components).forEach (c => { c.clear(); });

        setTimeout(() => {

            output.classList.remove('show');
            ReactDOM.unmountComponentAtNode(output);

        }, 3000);
    }

    handleSuccess(target, output) {

        const feedback = Feedback.success(this.props.settings.company_name);

        Helpers.removeClass(document.getElementsByClassName('form-group'), 'invalid');
        Helpers.removeClass(document.getElementsByClassName('form-control'), 'is-invalid');
        Helpers.removeClass(document.getElementsByTagName('input'), 'is-invalid');

        Scroll.animateScroll.scrollToTop();

        ReactDOM.render(<IntlProvider locale="en"><Output alert="success">{feedback}</Output></IntlProvider>, output);
        output.classList.add('show');

        this.handleEmail();
        this.handleReset(target, output);
    }

	render() {

        const { id, children } = this.props;
        
        if (children !== undefined) {

            const fields = Helpers.recursiveCloneChildren(this, children, { update: this.handleUpdate, id: id });
            
            return (

                <form id={id} onSubmit={this.handleSubmit} noValidate>
                    <fieldset>
                        {fields}
                    </fieldset>
                </form>
            )
        }
        else {

            return null;
        }
    }
}