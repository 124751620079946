/**
 * Meta component
 * 
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export default class Meta extends PureComponent {

	static propTypes = {
		
		data: PropTypes.object,
	}

	render() {
		
		const { data, settings } = this.props;

		if (settings !== null) {

			const { twitter_username, facebook_page_id, facebook_name, facebook_app_id, google_site_verification_code, bing_site_verification_code } = settings;
			const { title, lead, meta_robots, meta_title, meta_description, twitter_title, twitter_description, twitter_media, facebook_title, facebook_description, facebook_media, canonical_url } = data;
			
			const headline = meta_title ? meta_title : title[0].text;
			const description = meta_description ? meta_description : lead;

			return (

				<Helmet>

					<title>{headline}</title>
					
					<meta name="robots" content={meta_robots}/>
					<meta name="description" content={description}/>
					
					<meta name="twitter:card" content="summary_large_image"/>
					<meta name="twitter:site" content={twitter_username}/>
					<meta name="twitter:creator" content={twitter_username}/>
					<meta name="twitter:title" content={twitter_title ? twitter_title : headline}/>
					<meta name="twitter:description" content={twitter_description ? twitter_description : description}/>
					{ !! twitter_media.url && <meta name="twitter:image" content={twitter_media.url}/> }

					<meta property="fb:pages" content={facebook_page_id}/>
					<meta property="og:type" content="website"/>
					<meta property="og:site_name" content={facebook_name}/>
					<meta property="og:title" content={facebook_title ? facebook_title : headline}/>
					<meta property="og:description" content={facebook_description ? facebook_description : description}/>
					{ !! facebook_media.url && <meta property="og:image" content={facebook_media.url}/> }
					<meta property="og:url" content={window.location.href}/>
					<meta property="fb:app_id" content={facebook_app_id}/>

					<meta name="google-site-verification" content={google_site_verification_code}/>
					<meta name="msvalidate.01" content={bing_site_verification_code}/>

					{ !! canonical_url && <link rel="canonical" href={window.location.host + canonical_url}/> }
					<link rel="home" href={window.location.host}/>

				</Helmet>
			)
		}
		else {

			return (

				<Helmet>

					<title>{data.title}</title>
					
					<meta name="robots" content="noindex, nofollow"/>
					<meta name="description" content={data.description}/>

				</Helmet>
			)
		}
	}
}
