/**
 * Form feedback statements
 *
 */

import React from 'react';

const Feedback = {

	success: name => {
		
		return <React.Fragment>Thank you for contacting <strong>{name}</strong>. We'll get back to you as soon as possible.</React.Fragment>;
	}
}

export default Feedback

