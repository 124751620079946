/**
 * Elevator component
 * 
 */

import React from 'react';
import Scroll from 'react-scroll';
import { FormattedMessage } from 'react-intl';

export default class Elevator extends React.PureComponent {

	constructor(props) {
		
		super(props);

		this.handleClick = this.handleClick.bind(this);

		this.state = {

			visible: false,
			position: 0, 
			down: true
		}
    }

    componentDidMount() {

    	var scrolling = this;

    	document.addEventListener('scroll', function(e) {

    		scrolling.handleScroll();
    	});
    }

    handleScroll() {

    	let offset = window.pageYOffset;

		if (offset > this.state.position) {

			this.setState({ down: true });
		}
		else {

			this.setState({ down: false });
		}

		this.setState({ position: offset });

    	if (window.pageYOffset > 680 && this.state.down === false) {

    		this.setState({ visible: true });
    	}
    	else {

    		this.setState({ visible: false });
    	}
    }

    handleClick() {
    	
		Scroll.animateScroll.scrollToTop();
    }
	
	render() {
		
		const { visible } = this.state;

		return (

			<div className={visible ? 'layout-elevator show' : 'layout-elevator'} id="layout-elevator" aria-hidden="true"><button type="button" onClick={this.handleClick} aria-hidden={visible ? 'false' : 'true'}><i className="icon i-arrow-up i-3x"><FormattedMessage id="button.elevator"/></i></button></div>
		)
	}
}
