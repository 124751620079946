/**
 * Backdrop component
 * 
 */

import React from 'react';

export default class Backdrop extends React.PureComponent {

	constructor(props) {
		
		super(props);

		this.state = {

			visible: false
		}
    }
	
	render() {
		
		const { visible } = this.state;

		return (

			<div className={visible ? 'layout-backdrop show' : 'layout-backdrop'} id="layout-backdrop" aria-hidden="true"></div>
		)
	}
}
