/**
 * Submit component
 * 
 */

import React, { PureComponent } from 'react';

export default class Submit extends PureComponent {
	
	static displayName = 'submit';

	render() {

		const { children: label } = this.props;
		
		return (

			<button type="submit" className="btn btn-primary btn-feedback" name="next">{label}</button>
		)
	}
}
