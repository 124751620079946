/**
 * Section testimonial component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Img from '../img/img';

export default class SectionTestimonial extends PureComponent {

	render() {
		
		const { data } = this.props;
		
		return (
			
			<div className="layout-section section-highlight section-center">
				<div className="container">

					{ !! data.title && <h2>{data.title}</h2> }

					<blockquote className="blockquote">
						{ !! data.photo.url && <figure className="blockquote-image"><Img data={data.photo} width="120" height="120"/></figure> }
						{ !! data.text && <p>"{data.text}"</p> }
						{ !! data.author && <footer className="blockquote-footer">{data.author} <cite title="Source Title">{data.position}</cite></footer> }
					</blockquote>

				</div>
			</div>
		);
	}
}
