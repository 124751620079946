/**
 * Meta analytics component
 * 
 */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Functions

import Tracking from '../../../utils/functions/analytics';

export default class Analytics extends PureComponent {

	static propTypes = {

		location: PropTypes.object
	}
	
	componentDidMount() {

		const { location } = this.props;

		setTimeout(() => { this.componentTracking(location); }, 2000);
	}

	componentDidUpdate(newProps) {

		if (newProps.location.pathname !== this.props.location.pathname) {

			this.componentTracking(newProps.location);
		}
	}

	componentTracking(location) {

		setTimeout(() => { Tracking.page(location.pathname); }, 100);
	}

	render() {

		return null;
	}
}
