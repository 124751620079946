/**
 * Contact form component
 * 
 */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';

// Fields

import Input from '../fields/input';
import Radio from '../fields/radio';
import Option from '../fields/option';
import Textarea from '../fields/textarea';
import Submit from '../fields/submit';

// Form

import Rendering from '../rendering/rendering';

class Contact extends PureComponent {

    render() {

    	const { settings, device } = this.props;

    	return (

            <Rendering id="contact-inquiry" settings={settings} device={device}>
                <div className="form-section" aria-labelledby="section-heading-a" role="group">

                    <h4 id="section-heading-a">Tell me a bit about yourself</h4>

                    <Input type="text" name="firstname" label="false" group="form-column" autofill="given-name" required="true" placeholder="First name" value="">First name</Input>
                    <Input type="text" name="lastname" label="false" group="form-column" autofill="family-name" required="true" placeholder="Last name" value="">Last name</Input>
                    <Input type="text" name="company" label="false" group="form-column" autofill="organization" required="false" placeholder="Company" value="">Company</Input>
                    <Input type="url" name="website" label="false" group="form-column" autofill="url" required="false" placeholder="Website" value="">Website</Input>
                    <Input type="email" name="email" label="false" group="form-column" autofill="email" required="true" placeholder="Email" value="">Email</Input>
                    <Input type="tel" name="phone" label="false" group="form-column" autofill="tel" required="false" placeholder="Phone" value="">Phone</Input>

                </div>
                <div className="form-section" aria-labelledby="section-heading-b" role="group">

                    <h4 id="section-heading-b">What can I help you with?</h4>

                    <Radio name="project" legend="Select your project type" label="false" required="true" checked="">
                        <Option value="Just saying Hello!">Just saying Hello!</Option>
                        <Option value="Branding">Branding</Option>
                        <Option value="Consulting">Consulting</Option>
                        <Option value="Marketing">Marketing</Option>
                        <Option value="UI/UX Design">UI/UX Design</Option>
                        <Option value="Development">Development</Option>
                    </Radio>

                </div>
                <div className="form-section" aria-labelledby="section-heading-c" role="group">

                    <h4 id="section-heading-c">What is your budget?</h4>

                    <Radio name="budget" legend="Select your budget" label="false" required="true" checked="">
                        <Option value="None">None</Option>
                        <Option value="$2,500 - $5,000">$2,500 - $5,000</Option>
                        <Option value="$5,000 - $10,000">$5,000 - $10,000</Option>
                        <Option value="$10,000 - $20,000">$10,000 - $20,000</Option>
                        <Option value="$20,000 - $30,000">$20,000 - $30,000</Option>
                        <Option value="More than $30k+">More than $30k+</Option>
                    </Radio>

                </div>
                <div className="form-section" aria-labelledby="section-heading-d" role="group">

                    <h4 id="section-heading-d">Could you please describe your project?</h4>

                    <Textarea name="message" label="false" required="true" placeholder="" value="">Project description</Textarea>

                    <Input type="captcha" name="captcha" label="false" required="false" value="">Captcha</Input>
                    <Input type="hidden" name="tracking" label="false" required="false" value={this.props.location.pathname}>Tracking</Input>
                    <Input type="hidden" name="success" label="false" required="false" value="0">Success</Input>

                </div>
                <div className="form-button">

                    <Submit>Send Request</Submit>

                </div>
            </Rendering>
        )
    }
}

export default (withRouter(Contact))
