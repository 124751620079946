/**
 * Blocks component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

// Functions

import Helpers from '../../utils/functions/helpers';

export default class Blocks extends PureComponent {

	render() {

		const { items } = this.props;
				
		if (Helpers.isEmpty(items) === false) {

			return (

				<div className="stack stack-deck" data-items="2" data-xs-items="1">
					{
						items.map((item, i) => {

							return (

								<div className="item" key={'col-' + i}>
									
									{ !! item.title && <h2 className="h3">{item.title}</h2> }
									{ !! item.text && RichText.render(item.text, Helpers.linkResolver) }

								</div>
							)

						})
					}
				</div>
			)
		}
		else {

			return null;
		}
	}
}
