/**
 * 404 not found router
 * 
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Hero from '../../components/hero/hero';
import Article from '../../components/article/article';

// Meta
import Head from '../meta/head/head';
import Theme from '../meta/theme/theme';

export default class NotFound extends React.Component {
	
	render() {
		
		const { settings } = this.props;
		
		if (settings) {

			const data = { title : '', lead : '', description : '' };

			return (

				<React.Fragment>

					<Head data={data} settings={null}></Head>
					<Theme theme={null}></Theme>

					<Hero data={data} layout="aside"></Hero>

					<Article layout="full">

						<div className="layout-section">
							<div className="container">

								<h1><FormattedMessage id="error.404.title"/></h1>
								<p className="lead"><FormattedMessage id="error.404.description"/></p>
								
							</div>
						</div>

					</Article>

				</React.Fragment>
			)
		}
		else {

			const data = { title : '', lead : '', description : '' };

			return (

				<React.Fragment>

					<Head data={data} settings={null}></Head>
					<Theme theme={null}></Theme>

					<Hero data={data} layout="aside"></Hero>

					<Article layout="full">

						<div className="layout-section">
							<div className="container">

								<h1><FormattedMessage id="error.404.title"/></h1>
								<p className="lead"><FormattedMessage id="error.404.description"/></p>
								
							</div>
						</div>

					</Article>

				</React.Fragment>
			)
		}
    }
}