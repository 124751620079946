/**
 * Showcase description component
 * 
 */

import React, { PureComponent } from 'react';
import { RichText } from 'prismic-reactjs';

// Components

import Columns from '../columns/columns';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcaseArticle extends PureComponent {

	render() {
		
		const { data } = this.props;

		const visible = Helpers.isVisible(data.visible);
		
		if (visible) {

			return (

				<div className="layout-section section-showcase section-article">
					<div className="container">
						
						{ !! data.title && data.lead === null && <h3>{data.title}</h3> }
						{ !! data.title && data.lead !== null && <h2>{data.title}</h2> }
						{ !! data.lead && <h3>{data.lead}</h3> }

						<Columns data={data.text}></Columns>

						{ !! data.bullet_list && RichText.render(data.bullet_list, Helpers.linkResolver) }

					</div>
				</div>
			)
		}
		else {

			return '';
		}
	}
}
