/**
 * Drawer component
 * 
 */

import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// Reducers

import { toggleDrawer } from '../../../utils/reducers/drawer';

// Functions

import drawer from '../../../utils/functions/drawer';

// Components

import Logo from '../../../components/logo/logo';
import Social from '../../../components/social/social';

class Drawer extends React.Component {

	constructor(props) {

		super(props);
		
    	this.handleClick = this.handleClick.bind(this);
	}

	handleClick(event) {

		this.props.toggleDrawer(this.props.state);
	}

	componentDidUpdate() {

		drawer.transition(this.props.state);
	}
	
	render() {
		
		const { children, settings } = this.props;

		if (settings) {

			const { company_drawer_logo } = settings;

			const classes = this.props.state.drawer === 'open' ? 'layout-drawer layout-transition show' : 'layout-drawer layout-transition hidden';
			const dialog = this.props.state.drawer === 'open' ? 'drawer show' : 'drawer';
			const hidden = this.props.state.drawer === 'open' ? 'false' : 'true';

			return (

				<div className={classes} id="layout-drawer" aria-hidden={hidden}>

					<div className={dialog} tabIndex="-1" role="dialog">
						<div className="drawer-dialog" id="layout-drawer-dialog" role="document">
							<div className="head">

								<Logo data={company_drawer_logo} layout="drawer-logo"></Logo>
								<button type="button" className="toggle toggle-button drawer-close" aria-controls="layout-drawer" onClick={this.handleClick}><i className="icon i-close"><FormattedMessage id="button.drawer.close"/></i></button>

							</div>
                            <div className="body">

                            	<div className="social drawer-social">
									<Social className="nav-social-drawer" data={settings}></Social>
								</div>

								{children}

                            </div>
						</div>
					</div>

				</div>
			)
		}
		else {

			return null;
		}
	}
}

// Get state from react redux

const mapStateToProps = (state) => ({ state: state.drawer });

// Update state in react redux

const mapDispatchToProps = (dispatch) => {

	return {
		
		dispatch, 
		
		toggleDrawer: (state) => {
			
			dispatch(toggleDrawer(state));
		}
	}
};

// Exporting our component via react redux

export default connect( mapStateToProps, mapDispatchToProps )(Drawer);
