/**
 * Logo component
 * 
 */

import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

// Components

import Img from '../img/img';

// Functions

import Tracking from '../../utils/functions/analytics';

class Logo extends PureComponent {

	constructor(props) {

		super(props);
		
    	this.handleClick = this.handleClick.bind(this);
	}

	handleClick(event) {

		Tracking.navigation({ action: 'logo', label: event.target.text });
	}

	render() {

		const { layout } = this.props;
		
		const classes = 'logo ' + layout;

		return <div className={classes}><Link to="/" onClick={this.handleClick}><Img data={this.props.data}/></Link></div>;
	}
}

export default withRouter(Logo)