/**
 * Cards component
 * 
 */

import React, { PureComponent } from 'react';

// Functions
import Helpers from '../../utils/functions/helpers';

// Components

import CardSection from './card-section';
import CardWidget from './card-widget';

export default class Cards extends PureComponent {

	render() {
		
		const { items, data, template } = this.props;
		
		switch (template) {
			
			case 'section_services':
			case 'section_projects':
				return (

					<div className="stack stack-deck" data-items="2" data-md-items="1">
						{
							items.map((item, i) => {

								return (
									<div className="item" key={'card-' + i}>

										<CardWidget data={item} />

									</div>
								)
							})
						}
					</div>
				)

			case 'section_work':
				return (

					<div className="stack stack-masonry" data-items="2" data-md-items="1">
						<div className="item">

							<h2>{data.title}</h2>
							<p className="lead">{data.lead}</p>

						</div>
						{
							items.map((item, i) => {

								const visible = Helpers.isVisible(item.work_card_visible);
								
								if (visible) {

									return (
										<div className="item" key={'card-' + i}>

											<CardWidget data={item} />
											
										</div>
									)
								}
								else {

									return '';
								}
							})
						}
					</div>
				)
			
			case 'section_features':
				return (

					<div className="stack stack-deck" data-items="1">
						{
							items.map((item, i) => {

								return (
									<div className="item" key={'card-' + i}>

										<CardSection data={item} count={i} />
										
									</div>
								)
							})
						}
					</div>
				)
			
			default:
				return ''
		}
	}
}
