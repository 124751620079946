/**
 * Content component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import ContentText from './content-text';
import ContentCard from './content-card';
import ContentQuote from './content-quote';

export default class Content extends PureComponent {

	render() {
		
		const { data } = this.props;

		if (data.body.length) {

			return data.body.map((item, i) => {

				switch (item.slice_type) {

					case 'content_text':
						return <ContentText key={'content-' + i} items={item.items} data={item.primary} template={item.slice_type}></ContentText>;

					case 'content_card':
						return <ContentCard key={'content-' + i} items={item.items} data={item.primary} template={item.slice_type}></ContentCard>;
						
					case 'content_quote':
						return <ContentQuote key={'content-' + i} items={item.items} data={item.primary} template={item.slice_type}></ContentQuote>;
						
					default:
						return null;
				}
			});
		}
		else {

			return null;
		}
	}
}
