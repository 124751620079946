/**
 * Combine reducers
 *
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// Let's combine our reducer states

import drawer from './drawer';

const createRootReducer = (history) => combineReducers({
	
	drawer,
	router: connectRouter(history)
})

export default createRootReducer;
