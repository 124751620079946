/**
 * Meta microdata component
 * 
 */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class Microdata extends PureComponent {

	static propTypes = {
		
		children: PropTypes.node,
	}

	render() {

		const { render, settings, article } = this.props;

		switch (render) {

			case 'company':
				
				return (

					<React.Fragment>
						<div className="microdata" itemScope itemType="http://schema.org/Organization">
						    { !! settings.company_name && <meta itemProp="name" content={settings.company_name} /> }
						    { !! settings.company_website && <meta itemProp="url" content={settings.company_website} /> }
						    { !! settings.company_logo && <meta itemProp="logo" content={settings.company_logo.url} /> }
						    { !! settings.company_founding_date && <meta itemProp="foundingDate" content={settings.company_founding_date} /> }
						    { !! settings.company_legal_name && <meta itemProp="legalName" content={settings.company_legal_name} /> }
						    { !! settings.company_alternate_name && <meta itemProp="alternateName" content={settings.company_alternate_name} /> }
						    { !! settings.company_description && <meta itemProp="description" content={settings.company_description} /> }
						    { !! settings.company_image.url && <meta itemProp="image" content={settings.company_image.url} /> }
						    <meta itemProp="additionalType" content="http://schema.org/Service" />
							<div itemProp="contactPoint" itemScope itemType="http://schema.org/ContactPoint">
							    { !! settings.company_contact_type && <meta itemProp="contactType" content={settings.company_contact_type} /> }
							    { !! settings.company_contact_email && <meta itemProp="email" content={settings.company_contact_email.replace('@', '&#x40;').replace('.', '&#x002E;')} /> }
							    { !! settings.company_contact_number && <meta itemProp="telephone" content={settings.company_contact_number} /> }
							    { !! settings.company_website && <meta itemProp="url" content={settings.company_website} /> }
							</div>
						</div>
						<div className="microdata" itemProp="founders" itemScope itemType="http://schema.org/Person">
						    { !! settings.company_founder && <meta itemProp="name" content={settings.company_founder} /> }
						</div>
						<div className="microdata" itemProp="openingHoursSpecification" itemScope itemType="http://schema.org/OpeningHoursSpecification">
							{ !! settings.company_operates && <meta itemProp="dayOfWeek" content={settings.company_operates} /> }
							{ !! settings.company_opens && <meta itemProp="opens" content={settings.company_opens} /> }
							{ !! settings.company_closes && <meta itemProp="closes" content={settings.company_closes} /> }
						</div>
						<div className="microdata" itemProp="address" itemScope itemType="http://schema.org/PostalAddress">
						    { !! settings.company_street_address && <meta itemProp="streetAddress" content={settings.company_street_address} /> }
						    { !! settings.company_district && <meta itemProp="addressLocality" content={settings.company_district} /> }
						    { !! settings.company_postal_code && <meta itemProp="postalCode" content={settings.company_postal_code} /> }
						    { !! settings.company_city && <meta itemProp="addressRegion" content={settings.company_city} /> }
						    { !! settings.company_country && <meta itemProp="addressCountry" content={settings.company_country} /> }
						</div>
						<div className="microdata" itemProp="geo" itemScope itemType="http://schema.org/GeoCoordinates">
							{ !! settings.company_location && <meta itemProp="latitude" content={settings.company_location.latitude} /> }
							{ !! settings.company_location && <meta itemProp="longitude" content={settings.company_location.longitude} /> }
						</div>
					</React.Fragment>
				)

			case 'article':
			case 'post':

				const headline = article.data.meta_title ? article.data.meta_title : article.data.title[0].text;
				const description = article.data.meta_description ? article.data.meta_description : article.data.lead;
				const type = render === 'article' ? 'Article' : 'BlogPosting';
				
				return (

					<React.Fragment>
						<div itemScope itemType={'http://schema.org/' + type}>
							<div className="microdata">
								<meta itemScope itemProp="mainEntityOfPage" itemType="https://schema.org/WebPage" itemID="https://google.com/article"/>
								<meta itemProp="headline" content={headline} />
								<meta itemProp="description" content={description} />
								<meta itemProp="datePublished" content={article.first_publication_date} />
								<meta itemProp="dateModified" content={article.last_publication_date} />
								<meta itemProp="mainEntityOfPage" content={window.location.href} />
							</div>
							<div className="microdata" itemProp="image" itemScope itemType="http://schema.org/ImageObject">
								{ !! article.data.meta_image.url && <meta itemProp="url" content={article.data.meta_image.url} /> }
								{ !! article.data.meta_image.url && <meta itemProp="width" content={article.data.meta_image.dimensions.width} /> }
								{ !! article.data.meta_image.url && <meta itemProp="height" content={article.data.meta_image.dimensions.height} /> }
							</div>
							<div className="microdata" itemProp="author" itemScope itemType="http://schema.org/Person">
								{ !! article.data.author_name && <meta itemProp="name" content={article.data.author_name} /> }
								{ !! article.data.author_description && <meta itemProp="description" content={article.data.author_description} /> }
								{ !! article.data.author_image.url && <meta itemProp="image" content={article.data.author_image.url} /> }
							</div>
							<div className="microdata" itemProp="publisher" itemScope itemType="http://schema.org/Organization">
								{ !! settings.company_name && <meta itemProp="name" content={settings.company_name} /> }
							    { !! settings.company_website && <meta itemProp="url" content={settings.company_website} /> }
							    <div itemProp="logo" itemScope itemType="http://schema.org/ImageObject">
				        			{ !! settings.company_logo.article && <meta itemProp="url" content={settings.company_logo.article.url} /> }
				        			{ !! settings.company_logo.article && <meta itemProp="width" content={settings.company_logo.article.width} /> }
				        			{ !! settings.company_logo.article && <meta itemProp="height" content={settings.company_logo.article.height} /> }
				        		</div>
							</div>
						</div>
					</React.Fragment>
				)
				
			default:
				
				return null;
		}
	}
}
