/**
 * Layout browser component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Img from '../img/img';

export default class Browser extends PureComponent {

	render() {
		
		const { image, label, size } = this.props;

		let standard = '';
		let retina = '';

		if (image.dimensions === undefined) {

			return '';
		}
		else {
			
			standard = image.url;
			retina = image.retina.url;

			if (size === 'lg') {

				return (

					<div className="ui ui-browser is-lg">
						<div className="head" aria-hidden="true"><i></i><i></i><i></i><span>{label}</span></div>
						<div className="body"><Img data={image} standard={standard} retina={retina} /></div>
						<div className="foot" aria-hidden="true"></div>
					</div>
				)
			}
			else {

				return (

					<div className={'ui ui-browser is-' + size}>
	                    <div className="head" aria-hidden="true"><i></i><i></i><i></i></div>
	                    <div className="body"><Img data={image} standard={standard} retina={retina} /></div>
	                    <div className="foot" aria-hidden="true"></div>
	                </div>
				)
			}
		}
	}
}
