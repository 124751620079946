/**
 * Content quote component
 * 
 */

import React, { PureComponent } from 'react';

export default class ContentQuote extends PureComponent {

	render() {
		
		const { data } = this.props;
		
		return (

			<blockquote className="blockquote is-inline"><p>{data.blockquote}</p></blockquote>
		);
	}
}
