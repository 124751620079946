/**
 * Carousel component
 * 
 */

import React, { PureComponent } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

// Components

import Img from '../img/img';

// Functions

import { Pagination, Navigation, Scrollbar } from 'swiper';


export default class Carousel extends PureComponent {

	static propTypes = {
		
	}
	
	render() {

		const { data, items } = this.props;

		var attr = [{}];

		if (data.pagination === true) {
			
			attr = data.navigation === true ? [{ loop: data.loop, pagination: { dynamicBullets: data.dynamic },  navigation: {}, modules: [Pagination, Navigation] }] : [{ loop: data.loop, pagination: { dynamicBullets: data.dynamic }, modules: [Pagination] }];
		}
		else if (data.scrollbar === true) {
			
			attr = data.navigation === true ? [{ loop: data.loop, scrollbar: { draggable: true }, navigation: {}, modules: [Scrollbar, Navigation] }] : [{ loop: data.loop, scrollbar: { draggable: true }, modules: [Scrollbar] }];
		}
		else if (data.navigation === true) {

			attr = [{ loop: data.loop, navigation: {}, modules: [Navigation]}];
		}
		
		return (

			<React.Fragment>

				<Swiper className="swiper swiper-paper" id={data.id} {...attr[0]}>
					{
						items.map((item, i) => {

							return <SwiperSlide key={'slide-' + i}><Img data={item.graphics}/></SwiperSlide>;
						})
					}
				</Swiper>

			</React.Fragment>
		)
	}
}
