/**
 * Showcase swiper component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Carousel from '../carousel/carousel';

// Functions

import Helpers from '../../utils/functions/helpers';


export default class ShowcaseSwiper extends PureComponent {

	render() {
		
		const { data, items } = this.props;

		const visible = Helpers.isVisible(data.visible);

		if (visible) {

			if (items[0].graphics.url !== undefined) {

				return (

					<div className="layout-section section-showcase section-swiper section-gradient">
						<div className="container">
							
							<Carousel data={data} items={items}></Carousel>

						</div>
					</div>
				)
			}
			else {

				return '';
			}
		}
		else {

			return '';
		}
	}
}
