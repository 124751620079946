/**
 * Input component
 * 
 */

import React, { PureComponent } from 'react';

export default class Input extends PureComponent {

	static displayName = 'input';

	constructor(props) {

		super(props);
		
    	this.state = {

    		value: props.value,
    		data: { label: props.children[0], name: props.name, id: props.id + '-' + props.name, type: props.type, required: props.required, value: props.value }
    	};
		    	
    	this.props.update(this.state.data);
    	this.handleChange = this.handleChange.bind(this);
	}

	handleChange(event) {

		const target = event.target;
		const group = target.closest('.form-group');
		const value = target.value;

		let temp = this.state.data;

		temp.value = value;

		this.setState({ value: value, data: temp });
		this.props.update(this.state.data);

		target.classList.remove('is-invalid');
		group.classList.remove('invalid');
	}

	clear() {
		
		let temp = this.state.data;

		temp.value = '';
		
		this.setState({ value: '', data: temp });
	}

	render() {

		const { children: text, type, label, placeholder, group, autofill } = this.props;

		let attributes = '';
		let classes = '';

		const id = this.state.data.id;
		const name = this.state.data.name;
		const required = this.state.data.required;

		const holder = placeholder ? { 'placeholder': placeholder } : {};
		const groups = group ? { 'className' : 'form-group ' + group } : { 'className' : 'form-group' };
		const screenreader = label === 'true' ? {} : { 'className': 'sr-only' };

		switch (type) {

			case 'text':

				classes = 'form-control';
				attributes = { 'autoComplete': autofill, 'spellCheck': 'false' };

			break
			case 'url':

				classes = 'form-control';
				attributes = { 'inputMode': 'url', 'autoCapitalize': 'off', 'autoComplete': autofill, 'spellCheck': 'false' };

			break
			case 'email':

				classes = 'form-control';
				attributes = { 'inputMode': 'email', 'autoCapitalize': 'off', 'autoComplete': autofill, 'spellCheck': 'false' };

			break
			case 'tel':

				classes = 'form-control';
				attributes = { 'inputMode': 'tel', 'autoComplete': autofill, 'spellCheck': 'false' };

			break
			case 'captcha':

				classes = 'form-captcha';
				attributes = { 'tabIndex': '-1' };

			break
			case 'hidden':

				classes = 'form-hidden';
				attributes = {};

			break
			default:

				classes = 'form-control';
				attributes = {'autoComplete': autofill, 'spellCheck': 'false'};

			break
		}
		
		if (type === 'hidden' || type === 'captcha') {

			return (

				<input type={type === 'captcha' ? 'text' : 'hidden'} className={classes} name={name} id={id} {...attributes} aria-required={required} aria-invalid="false" onChange={this.handleChange} value={this.state.value} />
			)
		}
		else {

			return (

				<div {...groups}>
					<label {...screenreader} htmlFor={id}>{text}</label>
					<input type={type} className={classes} name={name} id={id} {...attributes} aria-required={required} aria-invalid="false" {...holder} onChange={this.handleChange} value={this.state.value} />
				</div>
			)
		}	
	}
}
