/**
 * Columns component
 * 
 */

import React, { PureComponent } from 'react';

export default class Columns extends PureComponent {

	render() {

		const { data } = this.props;
		
		if (data.length >= 1) {

			return (

				<div className="type-column">
				{
					data.map((item, i) => {

						return <p key={'column-' + i} >{item.text}</p>
					})
				}
				</div>
			)
		}
		else {

			return null;
		}		
	}
}
