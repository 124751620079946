/**
 * Javascript drawer functions
 *
 */

const drawer = {

	transition: (state) => {
		
		const root = document.getElementsByTagName('html')[0];

		if (state.drawer === 'open') {
			
			root.classList.add('overflow', 'overflow-drawer');
		}
		else {

			root.classList.remove('overflow', 'overflow-drawer');
		}
	}
}

export default drawer
