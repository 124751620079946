/**
 * Img component
 * 
 */

import React, { PureComponent } from 'react';

export default class Img extends PureComponent {

	render() {

		const { url, retina, alt, dimensions } = this.props.data;
		const { classes, width, height, expand } = this.props;

		let srcset = retina === undefined ? url : url + ' 1x,' + retina.url + ' 2x';
		
		const classname = 'lazyload' + (classes === undefined ? '' : ' ' + classes);
		
		if (url) {

			return <img src={url} srcSet="/img/blank.png" data-srcset={srcset} className={classname} data-expand={expand} width={width ? width : dimensions.width} height={height ? height : dimensions.height} alt={alt||''} />;
		}
		else {

			return null;
		}
	}
}
