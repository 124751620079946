/**
 * Option component
 * 
 */

import React, { PureComponent } from 'react';

export default class Option extends PureComponent {
	
	static displayName = 'option';

	render() {

		const { children: label, type, id, name, value, checked, update, i } = this.props;

		const classes = checked === value ? { 'className': 'form-check option option-inline checked' } : { 'className': 'form-check option option-inline' };

		switch (type) {

			case 'radio':
				return (

					<div {...classes}><input type={type} name={name} id={id + '-' + i} onChange={update} checked={checked === value} value={value} /><label htmlFor={id + '-' + i}>{label}</label></div>
				)

			case 'checkbox':
				return (

					<div {...classes}><input type={type} name={name} id={id + '-' + i} onChange={update} checked={checked === value} value={value} /><label htmlFor={id + '-' + i}>{label}</label></div>
				)

			case 'option':
				return (

					<option value={value}>{label}</option>
				)

			default:
				return ''
		}
	}
}
