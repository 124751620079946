/**
 * Javascript help functions
 *
 */

import React from 'react';

const Helpers = {

	/**
	 * Removes a class name from an element
	 *
	 * @param  {Array} group - array of elements
	 * @param  {String} name - the class name to remove
	 * @param  {Boolean} parent - if parent element is target
	 *
	 */

	removeClass: (group, name, parent = false) => {

		[].forEach.call(group, function(el) { parent ? el.parentElement.classList.remove(name) : el.classList.remove(name) });
	},

	/**
	 * Adds a class name to an element
	 *
	 * @param  {Array} group - array of elements
	 * @param  {String} name - the class name to remove
	 * @param  {Boolean} parent - if parent element is target
	 *
	 */

	addClass: (group, name, parent = false) => {

		[].forEach.call(group, function(el) { parent ? el.parentElement.classList.add(name) : el.classList.add(name) });
	},

	/**
	 * Returns an element's offset position
	 *
	 * @param  {String} id - the element id
	 *
	 * @return {Array}
	 */

	offset: id => {

		const field = document.getElementById(id);

		const bounding = field.getBoundingClientRect();
		const left = window.pageXOffset || document.documentElement.scrollLeft;
		const top = window.pageYOffset || document.documentElement.scrollTop;
		
		return { top: bounding.top + top, left: bounding.left + left };
	},

	/**
	 * Recursively cloning react children
	 *
	 * @param  {Array} children - children to loop through
	 * @param  {Object} conductor - children to loop through
	 * @param  {Array} components - supported react components
	 *
	 * @return {Array} children
	 */

	recursiveCloneChildren: (parent, children, conductor = {}, components = []) => {

		let types = components.length === 0 ? ['input', 'textarea', 'radio', 'checkbox', 'select'] : components;

		return React.Children.map(children, (child, i) => {

			if (React.isValidElement(child)) {
				
				const handler = types.includes(child.type.displayName ? child.type.displayName.toLowerCase() : '') ? Object.assign({ i: i, ref: el => parent.components[child.props.name ? child.props.name : parent.props.name + '-' + i] = el }, conductor) : {};

				handler.children = Helpers.recursiveCloneChildren(parent, child.props.children, conductor, types);

				return React.cloneElement(child, handler);
			}
			else {

				return child;
			}
		})
	}
}

export default Helpers