/**
 * Social navigation component
 * 
 */

import React, { PureComponent } from 'react';

// Functions

import Tracking from '../../utils/functions/analytics';

export default class Social extends PureComponent {

	constructor(props) {

		super(props);
		
    	this.handleClick = this.handleClick.bind(this);
	}

	handleClick(event) {

		Tracking.navigation({ action: 'social', label: event.currentTarget.firstElementChild.textContent });
		Tracking.outbound({ action: 'social', label: event.currentTarget.href });
	}

	render() {

		const { className } = this.props;		
		const { social_media: channels } = this.props.data;
		
		const classes = 'nav nav-social ' + className;

		if (channels.length) {

			return (

				<ul className={classes}>
				{

					channels.map((item, i) => {

						if (item.social_media_visible === true) {

							return <li className="nav-item" key={'channel-' + i}><a href={item.social_media_url} className="nav-link" target="_blank" rel="noopener noreferrer" onClick={this.handleClick}><i className={'icon i-social-' + item.social_media_name.toLowerCase()}>{item.social_media_name}</i></a></li>;
						}
						else {

							return '';
						}
					})

				}
				</ul>
			)	
		}
	}
}