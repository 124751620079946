/**
 * Section work component
 * 
 */

import React, { PureComponent } from 'react';

// Components

import Card from '../card/card';

export default class SectionWork extends PureComponent {

	render() {
		
		const { data, items, template } = this.props;

		return (
			
			<div className="layout-section">
				<div className="container">

					<Card data={data} items={items} template={template} />

				</div>
			</div>
		);
	}
}
