/**
 * Showcase display component
 * 
 */

import React, { PureComponent } from 'react';

// Layout

import Browser from '../browser/browser';

// Components

import Img from '../img/img';

// Functions

import Helpers from '../../utils/functions/helpers';

export default class ShowcaseDisplay extends PureComponent {

	render() {
		
		const { data, items, label } = this.props;

		const styling = data.styling === 'Default' ? '' : 'section-' + data.styling.toLowerCase();
		const visible = Helpers.isVisible(data.visible);

		if (data.image.url === undefined && items[0].graphics.retina.dimensions === undefined) {

			return '';
		}
		else {

			if (visible) {

				if (data.image.url && items.length === 0) {

					return (

						<div className={'layout-section section-showcase section-display ' + styling}>
							<div className="container">

								<figure className="image"><Img data={data.image}/></figure>

							</div>
						</div>
					)
				}
				else if (data.image.url && items.length >= 1) {

					return (

						<div className={'layout-section section-showcase section-display ' + styling}>
							<div className="container">
							{
								items.map((item, i) => {

									return <Browser key={'browser-' + i} image={item.graphics} label={label} size={'lg'}></Browser>;
								})

							}
								<figure className="image"><Img data={data.image}/></figure>

							</div>
						</div>
					)
				}
				else {

					return (

						<div className={'layout-section section-showcase section-display ' + styling}>
							<div className="container">
							{
								items.map((item, i) => {
									
									return <Browser key={'browser-' + i} image={item.graphics} label={label} size={'lg'}></Browser>;
								})

							}						
							</div>
						</div>
					)
				}
			}
			else {

				return '';
			}
		}
	}
}
