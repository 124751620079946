/**
 * drawer states
 *
 */

export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';

// Setup states

const initial = { drawer: 'close' };

// Export state

export default (state = initial, action) => {

	switch (action.type) {

		case OPEN:
			return { ...state, drawer: 'open' };

		case CLOSE:
			return { ...state, drawer: 'close' };

		default:
			return state;
	}
}

// Toggle

export function toggleDrawer(state) {
	
	return {

		type: state.drawer ===  'open' ? CLOSE : OPEN
	}
}
