/**
 * Javascript help functions
 *
 */

import React from 'react';
import { Elements } from 'prismic-reactjs';

const Helpers = {

	/**
	 * Returns a url with the correct url structure
	 *
	 * @param  {Array} url - the document link values
	 *
	 * @return {String} url
	 */

	linkResolver: url => {
		
		if (url.isBroken === true) {

			return undefined;
		}
		else {
			
			switch (url.type) {

				case 'home':
					return '/';

				case 'page':
					return '/' + url.uid;

				case 'contact':
					return '/' + url.uid;

				case 'article':
					return '/' + url.uid;

				case 'showcase':

					const language = Helpers.getWebsiteLanguage();
					const route = Helpers.getRouteURL(language);

					return route + url.uid;

				case undefined:
					return url.url;

				default:
					return '/';
			}
		}
	},

	/**
	 * Returns a public IP address
	 *
	 * @return {String}
	 */

	publicIP: async endpoint => {

		const response = await fetch(endpoint || 'https://api.ipify.org');
		const ip = response.text();

		return ip;
	},

	/**
	 * Checks if an array of objcets is empty
	 *
	 * @param  {Array} value - the array to check
	 *
	 * @return {Boolean}
	 */

	isEmpty: items => {

		const value = items.filter(i => Object.values(i).filter(v => v !== null & v !== undefined).length > 0).length === 0 ? true : false;

		return value;
	},

	/**
	 * Checks for Array
	 *
	 * @param  {Array} value - the array to check
	 *
	 * @return {Boolean}
	 */

	isArray: value => {

		return value && typeof value === 'object' && value.constructor === Array;
	},

	/**
	 * Checks for Object
	 *
	 * @param  {Array} value - the array to check
	 *
	 * @return {Boolean}
	 */

	isObject: value => {

		return value && typeof value === 'object' && value.constructor === Object;
	},

	/**
	 * Returns an object array with updated keys
	 *
	 * @param  {Array} data - the array to loop through
	 *
	 * @return {Array} ret
	 */

	renameObjectKeys: data => {

		if (data) {

			data.forEach(function(item) {

				const key = item.slice_type.includes('section') ? item.slice_type.substr(item.slice_type.lastIndexOf('_') + 1) + '_' : item.slice_type.substr(item.slice_type) + '_';
				
				Helpers.renameKeys(item.primary, key);
				Helpers.renameKeys(item.items);
			});
		}
	},

	/**
	 * Returns the route based on language
	 *
	 * @return {String}
	 */

	getRouteURL: language => {

		switch (language) {

			case 'sv':

				return '/arbete/';

			default:

				return '/work/';
		}
	},

	/**
	 * Checks for Array
	 *
	 * @param  {Boolean} true or false
	 *
	 * @return {Boolean}
	 */

	isVisible: value => {
		
		const visible = value === false && window.location.hostname !== 'localhost' ? false : true;

		return visible
	},

	/**
	 * Returns HTML language of website
	 *
	 * @return {String}
	 */

	getWebsiteLanguage: () => {

		const language = typeof window !== 'undefined' ? document.documentElement.lang : 'en';

		return language;
	},

	/**
	 * Returns a serialized rich text
	 *
	 * @return {String}
	 */

	propsWithUniqueKey: (props, key) => {

		return Object.assign(props || {}, { key });
	},

	/**
	 * Returns a serialized rich text to span
	 *
	 * @return {String}
	 */

	htmlSerializerToSpan: (type, element, content, children, key) => {

		var props = {};

		switch(type) {

			case Elements.paragraph:
				return React.createElement('span', Helpers.propsWithUniqueKey(props, key), children);

			default:
				return null;
		}
	},

	/**
	 * Returns an object array with rewritten keys
	 *
	 * @param  {Array} arr - the array to loop through
	 * @param  {string} prefix - the string to replace
	 *
	 * @return {Array} ret
	 */

	renameKeys: (arr, prefix = '', ret = {}) => {
		
	 	if (Array.isArray(arr) === true) {

	 		return arr.map(obj => {

	 			Object.keys(obj).forEach(function(k) { obj[prefix ? k.replace(prefix, '') : k.split('_').pop()] = obj[k]; });

	 			return obj;
			})
	 	}
	 	else {

	 		Object.keys(arr).forEach(function(k) { arr[prefix ? k.replace(prefix, '') : k.split('_').pop()] = arr[k]; });
	 		
	 		return arr;
		}
	}
}

export default Helpers
